import { combineReducers } from 'redux';

import initialState from 'store/initialState';
import {
  USER,
  BATCHES,
  BATCH,
  UPDATE_FILTERS,
  ITEMS,
  UPDATE_BATCH,
  SAVE_UPDATE_BATCH_STATUS,
  OFFER_CODES,
  OFFER_CODE,
  CREATE_BATCH,
  UPDATE_OFFER_CODE_FILTERS,
  UPDATE_OFFER_CODE,
  CREATE_OFFER_CODE,
  DELETE_BATCH,
} from 'actions/actionTypes';
import { createReducer } from 'reduxHelpers';

const batch = createReducer(BATCH, initialState.batch);
const batches = createReducer(BATCHES, initialState.batches);
const filters = createReducer(UPDATE_FILTERS, initialState.filters);
const user = createReducer(USER, initialState.user);
const createBatch = createReducer(CREATE_BATCH, initialState.createBatch);
const updateBatch = createReducer(UPDATE_BATCH, initialState.updateBatch);
const saveAndUpdateBatchStatus = createReducer(SAVE_UPDATE_BATCH_STATUS, initialState.saveAndUpdateBatchStatus);
const offerCodes = createReducer(OFFER_CODES, initialState.offerCodes);
const offerCode = createReducer(OFFER_CODE, initialState.offerCode);
const offerCodeFilters = createReducer(UPDATE_OFFER_CODE_FILTERS, initialState.offerCodeFilters);
const updateOfferCode = createReducer(UPDATE_OFFER_CODE, initialState.updateOfferCode);
const createOfferCode = createReducer(CREATE_OFFER_CODE, initialState.createOfferCode);
const loadRewardItems = createReducer(ITEMS, initialState.loadRewardItems);
const deleteBatch = createReducer(DELETE_BATCH, initialState.deleteBatch);

export default combineReducers({
  batch,
  batches,
  user,
  filters,
  loadRewardItems,
  updateBatch,
  saveAndUpdateBatchStatus,
  offerCodes,
  offerCode,
  createBatch,
  offerCodeFilters,
  updateOfferCode,
  createOfferCode,
  deleteBatch,
});
