import React, { useCallback } from 'react';
import DangerModal from 'components/DangerModal';

export function DeleteModalHolder({ visible, onToggle, batchData, onDelete }) {
  const onDeleteConfirmation = useCallback(() => {
    onDelete(batchData);
    onToggle();
  }, [batchData, onToggle, onDelete]);

  return <DangerModal visible={visible} onCancel={onToggle} batchData={batchData} onOk={onDeleteConfirmation} />;
}

export default DeleteModalHolder;
