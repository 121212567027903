import React from 'react';

function RewardBadge({ icon, buttonClass, badge }) {
  return (
    <span className={`tag-rewards btn ${buttonClass}`}>
      <span className={icon}></span>
      <span>{badge}</span>
    </span>
  );
}

export default RewardBadge;
