import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { Select } from 'antd';

import i18n from '_i18n';
import * as batchesActions from 'actions';
import BatchesPageComponent from 'pages/BatchesPage/components/BatchesPageComponent';
import Badge from 'components/Badges/RewardBadge';
import StatusBadge from 'components/Badges/BatchStatus';
import { BatchStatus, NotificationTypes, RewardType, SortOrder, UserRole } from 'enums';
import { DATE_PATTERN, FILTER_ALL, APP_ROUTES, NATIONAL_OPCO } from 'util/constants';
import HoverActions from './components/HoverActions';

const { Option } = Select;
function BatchesPageContainer({
  actions,
  vendorId,
  batchFetching,
  userRole,
  vendorList,
  opcoDetails,
  batches,
  batchesFetching,
  masterVendors,
  filters,
  offerCodeList,
  groupedOfferCodes,
  filtersUpdating,
}) {
  const history = useHistory();
  const { search } = useLocation();
  const batchId = queryString.parse(search)?.batchId?.trim() || null;
  const [gridScrollHeight, setGridScrollHeight] = useState('70vh');
  const { t } = useTranslation();
  const [orderType, setOrderType] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const createBatchFormRef = useRef(null);
  const [multipleSelectorVisible, toggleMultipleSelector] = useState(false);
  const [selectedBatchIds, setSelectedBatchIds] = useState([]);
  const [commonOfferCodeIds, setCommonOfferCodeIds] = useState([]);

  const onDelete = useCallback(
    (batchData) => {
      actions.deleteBatch(batchData?.batchId, false, history);
    },
    [actions, history]
  );

  const showMultipleSelector = useCallback(
    (selectedBatches) => {
      const commonCodes = _.intersection(..._.map(selectedBatches, (batch) => batch.offerCodeIds));

      setCommonOfferCodeIds(commonCodes);
      toggleMultipleSelector(true);
    },
    [setCommonOfferCodeIds, toggleMultipleSelector]
  );

  const hideMultipleSelector = useCallback(() => {
    setCommonOfferCodeIds([]);
    toggleMultipleSelector(false);
  }, [setCommonOfferCodeIds, toggleMultipleSelector]);

  useEffect(() => {
    if (vendorId && !filtersUpdating) actions.loadBatchesDetails();
    if (!vendorId && !filtersUpdating)
      actions.showNotification(
        i18n.t('rewards.user.error.message'),
        i18n.t('rewards.vendor.error.noVendorId.description'),
        NotificationTypes.ERROR
      );

    setSelectedBatchIds([]);
    hideMultipleSelector();
  }, [filters, vendorId, actions, filtersUpdating, hideMultipleSelector, setSelectedBatchIds]);

  useEffect(() => {
    if (userRole === UserRole.SYSCO_ASSOCIATE) actions.loadAllOfferCodes();
  }, [actions, userRole]);

  const onSearchBatchId = (e) => {
    if (e.keyCode === 13) {
      const batchIdString = e.target.value;
      if (batchIdString) {
        history.push({
          pathname: '/suite/rewards',
          search: `?batchId=${batchIdString?.trim()}`,
        });
      } else {
        history.push('/suite/rewards');
      }
    }
  };

  useEffect(() => {
    const vendorIds = _.map(vendorList, (vendor) => vendor.vendorId);
    let dataSearch = { ...filters };

    if (
      _.isEmpty(filters.vendorIds) ||
      (filters.vendorIds?.length > 1 && !_.isEqual(vendorIds, filters.vendorIds)) ||
      !_.includes(vendorIds, filters.vendorIds?.[0])
    ) {
      dataSearch = { ...dataSearch, vendorIds };
    }

    if (batchId !== filters.batchId) {
      dataSearch = { ...dataSearch, batchId, page: 1 };
    }

    if (!_.isEqual(filters, dataSearch)) actions.updateFilters(dataSearch);
  }, [batchId, filters, actions, vendorList]);

  const onResize = (_0, height) => {
    setGridScrollHeight(height - 150);
    setSelectedBatchIds([]);
    hideMultipleSelector();
  };

  const onChangePage = (current) => {
    if (current !== filters.page) actions.updateFilters({ ...filters, page: current });
  };

  const getStatusSource = () => {
    const statusSource = _.values(BatchStatus);

    return userRole === UserRole.SYSCO_ASSOCIATE
      ? statusSource.filter((status) => status !== BatchStatus.OPEN)
      : statusSource;
  };

  const navigateToOfferCodesList = useCallback(() => history.push(APP_ROUTES.OFFER_CODES_LIST), [history]);

  const onChangeRewardType = (key) => {
    const dataSearch = { ...filters, rewardType: key };
    actions.updateFilters(dataSearch);
  };

  const onChangeStatusType = (key) => {
    const dataSearch = { ...filters, batchStatus: key };
    actions.updateFilters(dataSearch);
  };

  const onChangeVendorType = (key) => {
    let dataSearch;
    if (key !== FILTER_ALL) {
      dataSearch = { ...filters, vendorIds: [key] };
    } else {
      dataSearch = { ...filters, vendorIds: _.map(vendorList, (vendor) => vendor.vendorId) };
    }
    actions.updateFilters(dataSearch);
  };

  const getSelectedBatches = useCallback(
    () =>
      _.map(selectedBatchIds, (selectedBatchId) => _.find(batches.items, (batch) => batch.batchId === selectedBatchId)),
    [selectedBatchIds, batches]
  );

  const isSameRewardTypeBatches = useCallback(() => {
    const selectedBatches = getSelectedBatches();
    return selectedBatches.every((batch) => batch?.rewardType === selectedBatches[0]?.rewardType);
  }, [getSelectedBatches]);

  const getMatchedOfferCodes = useCallback(
    (rewardType) => {
      return _.get(groupedOfferCodes, rewardType?.toUpperCase(), []);
    },
    [groupedOfferCodes]
  );

  const handleMultipleBatchSelection = useCallback(
    (selectedRowIds) => {
      const selectedRows = _.map(selectedRowIds, (id) => _.find(batches.items, (batch) => batch.batchId === id));
      const isEditable = _.every(selectedRows, (row) => row?.batchStatus === BatchStatus.REVIEWED);

      setSelectedBatchIds(selectedRowIds);

      if ((selectedRows?.length || 0) > 1 && isEditable) showMultipleSelector(selectedRows);
      else hideMultipleSelector();
    },
    [showMultipleSelector, hideMultipleSelector, batches, setSelectedBatchIds]
  );

  const rowSelection = {
    selectedRowKeys: selectedBatchIds,
    onChange: (selectedRowIds) => handleMultipleBatchSelection(selectedRowIds),
  };

  const onCreateBatchDetails = useCallback(
    ({ batchType, rewardType, batchNameFY, batchNameQ }) => {
      history.push({
        pathname: '/suite/rewards/batch/newBatch',
        search: queryString.stringify({
          rewardType,
          batchType,
          batchFY: batchNameFY,
          batchQuarter: rewardType === RewardType.SEASONAL ? batchNameQ : undefined,
        }),
      });
    },
    [history]
  );

  const handleBatchDetailsSubmit = useCallback(() => createBatchFormRef.current?.submitForm(), [createBatchFormRef]);

  const viewBatch = useCallback(
    (record) => {
      history.push(`${APP_ROUTES.BATCH_VIEW}/${record.batchId}`, { params: `${record.batchId}` });
    },
    [history]
  );

  const onSetOfferCode = useCallback(
    (value, record) => {
      const batch = { offerCodeIds: [value] || [], batchId: record.batchId, batchStatus: record.batchStatus };

      actions.saveAndUpdateBatchStatus({
        batchDataList: [batch],
        history,
        isBatchCreating: false,
        isMultipleBatchesSelected: true,
      });
    },
    [actions, history]
  );
  const handleClickHeader = (row) => {
    return {
      onClick: () => {
        const { dataIndex: sortBy } = row;
        let sortType = orderBy === sortBy ? orderType : null;
        if (sortType === null || orderBy === null) {
          sortType = SortOrder.ASC;
        } else if (sortType === SortOrder.DESC) {
          sortType = SortOrder.ASC;
        } else if (sortType === SortOrder.ASC) {
          sortType = SortOrder.DESC;
        }
        setOrderType(sortType);
        setOrderBy(sortBy);
        const sortDetails = { field: sortBy, order: sortType };
        const data = { ...filters, sort: sortDetails };
        actions.updateFilters(data);
      },
    };
  };

  const updateOfferCode = useCallback(
    (offerCode) =>
      setCommonOfferCodeIds(() => {
        const batchDataList = _.map(selectedBatchIds, (selectedBatchId) => {
          const { batchStatus } = _.find(batches?.items, (batch) => batch.batchId === selectedBatchId);

          return {
            batchId: selectedBatchId,
            batchStatus,
            offerCodeIds: [offerCode],
          };
        });
        actions.saveAndUpdateBatchStatus({
          batchDataList,
          history,
          isBatchCreating: false,
          isMultipleBatchesSelected: true,
        });

        return [offerCode];
      }),
    [actions, setCommonOfferCodeIds, selectedBatchIds, batches, history]
  );

  const approveBatches = useCallback(() => {
    actions.saveAndUpdateBatchStatus({
      batchStatus: BatchStatus.APPROVED,
      batchDataList: getSelectedBatches(),
      history,
      isMultipleBatchesSelected: true,
      isBatchCreating: false,
    });

    setSelectedBatchIds([]);
    hideMultipleSelector();
  }, [actions, getSelectedBatches, history, hideMultipleSelector, setSelectedBatchIds]);

  const rejectBatches = useCallback(() => {
    actions.saveAndUpdateBatchStatus({
      batchStatus: BatchStatus.REJECTED,
      batchDataList: getSelectedBatches(),
      history,
      isMultipleBatchesSelected: true,
      isBatchCreating: false,
    });

    setSelectedBatchIds([]);
    hideMultipleSelector();
  }, [actions, getSelectedBatches, history, hideMultipleSelector, setSelectedBatchIds]);

  const approveBatch = useCallback(
    (batchData) => {
      actions.saveAndUpdateBatchStatus({
        batchId: batchData?.batchId,
        batchStatus: BatchStatus.APPROVED,
        batchDataList: [batchData],
        comments: [],
        history,
        isMultipleBatchesSelected: true,
        isBatchCreating: false,
      });

      setSelectedBatchIds([]);
      hideMultipleSelector();
    },
    [actions, history, hideMultipleSelector, setSelectedBatchIds]
  );

  const rejectBatch = useCallback(
    (batchData) => {
      actions.saveAndUpdateBatchStatus({
        batchId: batchData?.batchId,
        batchStatus: BatchStatus.REJECTED,
        comments: [],
        batchDataList: [batchData],
        history,
        isMultipleBatchesSelected: true,
        isBatchCreating: false,
      });
      setSelectedBatchIds([]);
      hideMultipleSelector();
    },
    [actions, history, hideMultipleSelector, setSelectedBatchIds]
  );

  const columns = [
    {
      title: t('rewards.id'),
      dataIndex: 'batchId',
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      onHeaderCell: (row) => handleClickHeader(row),
      render: (text, record) => (
        <div
          id={`batches-page-table-batch-${record?.batchId}`}
          className="link-div"
          aria-hidden="true"
          onClick={() => viewBatch(record)}
        >
          {text}
        </div>
      ),
    },
    {
      title: t('rewards.batch.batchName'),
      dataIndex: 'batchName',
      onCell: (record) => ({ onClick: () => viewBatch(record) }),
      render: (text) => <span className="ant-clickable-row">{text}</span>,
    },
    {
      title: t('rewards.createdDate'),
      dataIndex: 'createdDate',
      defaultSortOrder: 'descend',
      render: (text) => moment(text).format(DATE_PATTERN),
    },
    {
      title: t('rewards.vendor'),
      dataIndex: 'vendorName',
      defaultSortOrder: 'descend',
    },
    {
      title: t('rewards.opco.name'),
      dataIndex: 'opcos',
      defaultSortOrder: 'descend',
      render: (opcos, record) => (
        <span id={`batches-page-table-batch-${record?.batchId}-type-label`} className="national-grid-label">
          {t(`rewards.batch.${opcos?.[0] === NATIONAL_OPCO ? 'national' : ''}`)}
        </span>
      ),
    },
    {
      title: t('rewards.rewardType'),
      dataIndex: 'rewardType',
      defaultSortOrder: 'descend',
      render: (text, record) => {
        const type = _.lowerCase(text);
        return (
          <Badge
            id={`batches-page-table-batch-${record?.batchId}-reward-type`}
            buttonClass={`tag-${type} edit-reward-tag`}
            icon={`tag-${type}-icon`}
            badge={t(`rewards.type.${type}`)}
          />
        );
      },
    },
    {
      title: t('rewards.status'),
      dataIndex: 'batchStatus',
      defaultSortOrder: 'descend',
      render: (text, record) => {
        const status = _.lowerCase(text);
        return (
          <StatusBadge
            id={`batches-page-table-batch-${record?.batchId}-status`}
            buttonClass={status}
            desc={`This batch has been ${status}`}
            title={t(`rewards.${status}`)}
          />
        );
      },
      onCell: (record) => ({
        onClick: () => viewBatch(record),
      }),
    },
    {
      title: 'Code',
      dataIndex: 'offerCodeIds',
      width: 'auto',
      render: (text, record) => {
        return (
          <div
            id={`batches-page-table-batch-${record?.batchId}-actions`}
            className="selection-actions code-multi-select-wrap"
          >
            <Select
              id={`batches-page-table-batch-${record?.batchId}-offer-code-selector`}
              disabled={_.upperCase(record.batchStatus) !== BatchStatus.REVIEWED || batchesFetching || batchFetching}
              style={{ width: '100%', minWidth: '50px', maxWidth: '200px', maxHeight: '64px', overflow: 'auto' }}
              defaultValue={text || []}
              value={text || []}
              className="code-multi-select"
              onChange={(v) => {
                onSetOfferCode(v, record);
              }}
            >
              {getMatchedOfferCodes(record?.rewardType)?.map((item) => (
                <Option
                  id={`batches-page-table-batch-offer-code${item?.offerCodeId}`}
                  key={item?.offerCodeId}
                  value={item?.offerCodeId}
                >
                  {item?.offerCodeId}
                </Option>
              ))}
            </Select>
          </div>
        );
      },
      hidden: `${userRole === UserRole.SYSCO_ASSOCIATE}`,
    },
    {
      title: '',
      dataIndex: 'controls',
      className: 'hover-actions',
      width: '200px',
      render: (text, record) => (
        <HoverActions
          id={`batches-page-table-batch-${record?.batchId}-hover-actions`}
          userRole={userRole}
          batchData={record}
          approveBatch={approveBatch}
          rejectBatch={rejectBatch}
          onDelete={onDelete}
        />
      ),
    },
  ].filter((item) => item.hidden !== 'false');

  return (
    <BatchesPageComponent
      actions={actions}
      vendorId={vendorId}
      userRole={userRole}
      vendorList={vendorList}
      opcoDetails={opcoDetails}
      batchItems={batches ? batches.totalItems : 0}
      masterVendors={masterVendors}
      filters={filters}
      history={history}
      statusSource={getStatusSource}
      onChangeRewardType={onChangeRewardType}
      onChangeStatusType={onChangeStatusType}
      rowSelection={rowSelection}
      columns={columns}
      onResize={onResize}
      batchesFetching={batchesFetching || batchFetching}
      data={batches?.items}
      onCreateBatchDetails={onCreateBatchDetails}
      onChangePage={onChangePage}
      onSearchBatchId={onSearchBatchId}
      navigateToOfferCodesList={navigateToOfferCodesList}
      createBatchFormRef={createBatchFormRef}
      handleBatchDetailsSubmit={handleBatchDetailsSubmit}
      multipleSelectorVisible={multipleSelectorVisible}
      offerCodeList={offerCodeList}
      selectedItems={getSelectedBatches()}
      commonOfferCodeIds={commonOfferCodeIds}
      updateOfferCode={updateOfferCode}
      onChangeVendorType={onChangeVendorType}
      approveBatches={approveBatches}
      rejectBatches={rejectBatches}
      gridScrollHeight={gridScrollHeight}
      isSameRewardTypeBatches={isSameRewardTypeBatches}
      getMatchedOfferCodes={getMatchedOfferCodes}
    />
  );
}

function mapStateToProps(state) {
  return {
    vendorId: !_.isEmpty(state.user.data.vendorList) ? state.user.data.vendorList[0].vendorId : null,
    batches: state.batches.data,
    userRole: state.user.data.userRole,
    vendorList: state.user.data.vendorList,
    batchesFetching: state.batches.fetching,
    batchFetching: state.updateBatch.fetching || state.saveAndUpdateBatchStatus.fetching || state.deleteBatch.fetching,
    filters: state.filters.data,
    offerCodeList: state.offerCodes.data.items,
    groupedOfferCodes: state.offerCodes.data.groupedOfferCodes,
    offerCodeFilters: state.offerCodeFilters.data,
    userFetching: state.user.fetching,
    filtersUpdating: state.filters.fetching,
  };
}

BatchesPageContainer.propTypes = {
  vendorId: PropTypes.string,
  actions: PropTypes.any,
  userRole: PropTypes.string,
  vendorList: PropTypes.array,
  opcoDetails: PropTypes.array,
  batchesFetching: PropTypes.bool,
  masterVendors: PropTypes.array,
  filters: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(batchesActions, dispatch) };
}
export default connect(mapStateToProps, mapDispatchToProps)(BatchesPageContainer);
