import React, { useCallback, useContext, useState } from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';

import OfferCodeViewPageContext from 'context/OfferCodeViewContext';
import compareObjectsByField from 'util/compareObjectsByField';
import { BATCHES_PAGE_SIZE } from 'util/constants';

const getColumns = (t) => [
  {
    title: t('rewards.item.productDescription'),
    dataIndex: 'description',
    sorter: compareObjectsByField('description'),
    sortDirections: ['ascend', 'descend', 'ascend'],
  },
  {
    title: t('rewards.item.brand'),
    dataIndex: 'brand',
    defaultSortOrder: 'descend',
  },
  {
    title: t('rewards.item.pack'),
    dataIndex: 'pack',
    defaultSortOrder: 'descend',
  },
  {
    title: t('rewards.item.size'),
    dataIndex: 'size',
    defaultSortOrder: 'descend',
  },
  {
    title: t('rewards.item.supc'),
    dataIndex: 'supc',
    defaultSortOrder: 'descend',
  },
  {
    title: `${t('rewards.item.mfg')} #`,
    dataIndex: 'mfgProductNumber',
    defaultSortOrder: 'descend',
  },
  {
    title: t('rewards.item.GTIN'),
    dataIndex: 'gtin',
    defaultSortOrder: 'descend',
  },
];

function OfferCodeItemList() {
  const { t } = useTranslation();
  const [gridScrollHeight, setGridScrollHeight] = useState('70vh');
  const { offerCode: { items } = {} } = useContext(OfferCodeViewPageContext);

  const onResize = useCallback(
    (_0, height) => {
      setGridScrollHeight(height - 100);
    },
    [setGridScrollHeight]
  );

  return (
    <div className="right-panel-offercode">
      <ReactResizeDetector handleHeight onResize={onResize}>
        <Table
          className="costing-batches-table"
          columns={getColumns(t)}
          dataSource={items}
          pagination={{ defaultPageSize: BATCHES_PAGE_SIZE }}
          scroll={{ x: '100%', y: gridScrollHeight }}
        />
      </ReactResizeDetector>
    </div>
  );
}

export default OfferCodeItemList;
