import * as _ from 'lodash';
import i18n from '_i18n';

import { BatchType, UserRole } from 'enums';
import { NEW_REWARD_POINTS, NO, OFFER_CODE_IDS, REJECT_ITEM, YES, NEW_BATCH } from './constants';

const updateItemFromCSVVendor = (item, uploadedItem) => {
  let newValue;
  const newItem = _.cloneDeep(item);
  const newRewardsPointsCSVKey = `${i18n.t('rewards.batchItem.newRewardPoints')}`;

  const trimmedNewRewardPoints = _.trim(uploadedItem?.[newRewardsPointsCSVKey]);

  if (trimmedNewRewardPoints?.length) newValue = _.toNumber(trimmedNewRewardPoints);

  if (newValue || newValue === 0) newItem[NEW_REWARD_POINTS] = newValue;

  return newItem;
};

const updateItemFromCSVAssociate = (item, uploadedItem, user) => {
  const newItem = _.cloneDeep(item);
  const currentTime = _.now();
  const offerCodeCSVKey = `${i18n.t('rewards.batchItem.offerCode')}`;
  const rejectStatusCSVKey = `${i18n.t('rewards.batchItem.reject')}`;

  // offerCode
  const offerCodeList = _.chain(uploadedItem?.[offerCodeCSVKey])
    .split(',')
    .map((code) => _.trim(code))
    .value();

  if (offerCodeList?.length) {
    newItem[OFFER_CODE_IDS] = offerCodeList;
  }

  // reject status
  const rejectStatus = _.chain(uploadedItem?.[rejectStatusCSVKey]).trim().upperCase().value();

  const rejected = rejectStatus === YES;

  if (rejected) {
    const rejectedTime = currentTime;
    const rejectorId = user.userId;

    newItem[REJECT_ITEM] = { rejected, rejectedTime, rejectorId };
  } else if (rejectStatus === NO) {
    newItem[REJECT_ITEM] = null;
  }

  return newItem;
};

/*  Checking if CSV contains all items in batch.
   This is to identify when a newly created batch is downloaded, then saved and if a user uploads the updated CSV
 */
const isValidItemsOnCSV = (items, updatedItems) => {
  const supcCSVKey = `${i18n.t('rewards.batchItem.supc')}`;

  return _.isEmpty(
    _.differenceWith(items, updatedItems, (item, updatedItem) =>
      _.isEqual(`'${item?.supc}'`, updatedItem?.[supcCSVKey])
    )
  );
};

const uploadItemMapper = (dataSource, uploadedItems, batchType, batchId, user) => {
  const isValidItems = isValidItemsOnCSV(dataSource, uploadedItems);
  return _.map(dataSource, (item) => {
    const searchedItem = _.find(
      uploadedItems,
      (uploadItem) => _.toNumber(uploadItem.SUPC?.replaceAll("'", '')) === _.toNumber(item.supc)
    );
    const itemBatchID = _.trim(searchedItem?.[i18n.t('rewards.batchItem.batchId')]);
    const isBatchIdValid =
      itemBatchID === batchId || (batchId === NEW_BATCH && !itemBatchID) || (!itemBatchID && isValidItems);

    const isEligibleToUpdate = batchType === BatchType.NATIONAL && isBatchIdValid && searchedItem;
    if (isEligibleToUpdate) {
      if (user.userRole === UserRole.MASTER_VENDOR) return updateItemFromCSVVendor(item, searchedItem);

      if (user.userRole === UserRole.SYSCO_ASSOCIATE) return updateItemFromCSVAssociate(item, searchedItem, user);
    }

    return item;
  });
};

export default uploadItemMapper;
