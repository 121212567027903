import { takeLatest, put, all, select, call, takeEvery } from 'redux-saga/effects';
import { getRequest, patchRequest, postRequest, putRequestCompressed, deleteRequest } from '_http';
import * as _ from 'lodash';

import {
  BATCHES,
  BATCH,
  UPDATE_FILTERS,
  USER,
  ITEMS,
  UPDATE_BATCH,
  SAVE_UPDATE_BATCH_STATUS,
  SHOW_NOTIFICATION,
  OFFER_CODES,
  OFFER_CODE,
  CREATE_BATCH,
  UPDATE_OFFER_CODE,
  UPDATE_OFFER_CODE_FILTERS,
  CREATE_OFFER_CODE,
  FETCH_ALL_OFFER_CODES,
  DELETE_BATCH,
} from 'actions/actionTypes';
import {
  APP_ROUTES,
  BAD_REQUEST_STATUS,
  BATCHES_PAGE_SIZE,
  NATIONAL_OPCO,
  NOT_FOUND_STATUS,
  USER_NOT_AUTHORIZED_STATUS,
} from 'util/constants';
import i18n from '_i18n';
import { action } from 'reduxHelpers';
import { BatchStatus, BatchType, NotificationTypes, UserRole } from 'enums';
import openNotification from 'components/openNotification';
import getSyscoUserId from 'util/getSyscoUserId';
import generateBatchName from 'util/generateBatchName';

export const getRole = (state) => state.user.data.userRole;
export const getUser = (state) => state.user.data;
export const getSelectedBatchId = (state) => state.batch.data.batchId;
export const getFilters = (state) => state.filters.data;
export const getVendorName = (state) => state.user.data.name;
export const getOfferCodeFilters = (state) => state.offerCodeFilters.data;
export const getBatchItems = (state) => state.batch.data.opcoItems;

function* handleUserSessionErrors(error) {
  let handled = false;

  if (error.response?.status === USER_NOT_AUTHORIZED_STATUS) {
    yield put(
      action(SHOW_NOTIFICATION, {
        description: i18n.t('rewards.user.error.notAuthorized.description'),
        className: NotificationTypes.ERROR,
        message: i18n.t('rewards.user.error.message'),
      })
    );

    handled = true;
  }

  return handled;
}

function* loadUserAsync({ userId }) {
  try {
    const response = yield call(getRequest, `/user/${getSyscoUserId(userId)}`);

    yield put({ type: USER.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: USER.FAILURE, payload: error.message });
    const handled = yield handleUserSessionErrors(error);
    if (!handled)
      yield put(
        action(SHOW_NOTIFICATION, {
          description: i18n.t('rewards.user.error.description'),
          className: NotificationTypes.ERROR,
          message: i18n.t('rewards.user.error.message'),
        })
      );
  }
}
function* loadBatchesAsync() {
  try {
    const filters = yield select(getFilters);
    const { page, ...filtersApplied } = filters;
    const role = yield select(getRole);

    const response = yield call(postRequest, `/batch/header/user?page=${page}&size=${BATCHES_PAGE_SIZE}`, {
      flowType: role,
      ...filtersApplied,
    });

    yield put({ type: BATCHES.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: BATCHES.FAILURE, payload: error.message });
    const handled = yield handleUserSessionErrors(error);
    if (!handled)
      yield put(
        action(SHOW_NOTIFICATION, {
          description: i18n.t('rewards.batches.error.description'),
          className: NotificationTypes.ERROR,
          message: i18n.t('rewards.batches.error.message'),
        })
      );
  }
}

function* updateBatchStatus({ data: { batchDataList } }) {
  const user = yield select(getUser);
  try {
    // temp resolution to track who changed a status of a batch
    const newList = batchDataList.map((batch) => {
      const updatedBatch = _.cloneDeep(batch);
      const userComments = _.reduce(
        batch.comments,
        (result, comment) => {
          if (comment && comment !== '') {
            result.push({
              text: comment,
              authorId: user.userId,
              authorName: user.name,
            });
          }

          return result;
        },
        []
      );

      if (!_.isEmpty(userComments)) updatedBatch.comments = userComments;

      return updatedBatch;
    });

    yield call(patchRequest, `/batch/status`, newList);
    yield put(
      action(SHOW_NOTIFICATION, {
        className: NotificationTypes.SUCCESS,
        message: i18n.t('rewards.batch.success.message'),
        description: i18n.t('rewards.batch.updated.success.description'),
      })
    );
  } catch (error) {
    const handled = yield handleUserSessionErrors(error);
    if (!handled) {
      let description = i18n.t('rewards.batch.update.error.description');
      const noUpdatedItemsError =
        _.some(batchDataList, (batch) => batch?.batchStatus === BatchStatus.PENDING) &&
        error.response?.status === BAD_REQUEST_STATUS;

      if (noUpdatedItemsError) description = i18n.t('rewards.batch.submit.noUpdatedItems.error.description');

      const noOfferCodesError =
        _.some(batchDataList, (batch) => batch.batchStatus === BatchStatus.APPROVED) &&
        error.response?.status === BAD_REQUEST_STATUS;

      if (noOfferCodesError) description = i18n.t('rewards.batch.offerCode.error.notFound.description');

      yield put(
        action(SHOW_NOTIFICATION, {
          description,
          className: NotificationTypes.ERROR,
          message: i18n.t('rewards.batch.update.status.error.message'),
        })
      );
    }
    throw error;
  }
}

function* loadBatchAsync({ selectedBatchId }) {
  let batchData;

  try {
    ({ data: batchData } = yield call(getRequest, `/batch/${selectedBatchId}`));

    const flowType = yield select(getRole);
    if (batchData && flowType === UserRole.SYSCO_ASSOCIATE && batchData.batchStatus === BatchStatus.PENDING) {
      const statusUpdatePayload = {
        data: {
          batchDataList: [{ batchId: selectedBatchId, batchStatus: BatchStatus.REVIEWED }],
        },
      };

      yield updateBatchStatus(statusUpdatePayload);

      // reload batch
      ({ data: batchData } = yield call(getRequest, `/batch/${selectedBatchId}`));
    }

    yield put({ type: BATCH.SUCCESS, payload: batchData });
  } catch (error) {
    yield put({ type: BATCH.FAILURE, error: error.message });
    const handled = yield handleUserSessionErrors(error);
    if (!handled)
      yield put(
        action(SHOW_NOTIFICATION, {
          description:
            error.response?.status === NOT_FOUND_STATUS
              ? i18n.t('rewards.batch.notFound.error.description')
              : i18n.t('rewards.batch.error.description'),
          className: NotificationTypes.ERROR,
          message: i18n.t('rewards.batch.error.message'),
        })
      );
  }
}

function* loadRewardItems({ vendorId, rewardType, batchFY, batchQuarter }) {
  try {
    const response = yield call(getRequest, `/item/?vendorId=${vendorId}&rewardType=${rewardType}`);
    const vendorName = yield select(getVendorName);

    const newBatch = {
      batchName: generateBatchName(rewardType, batchFY, batchQuarter),
      batchType: BatchType.NATIONAL,
      opcos: [NATIONAL_OPCO],
      rewardType,
      batchStatus: BatchStatus.OPEN,
      vendorId,
      vendorName,
      offerCodeIds: [],
      opcoItems: response.data,
      comments: [],
    };

    yield put({ type: ITEMS.SUCCESS });
    yield put({ type: BATCH.SUCCESS, payload: newBatch });
  } catch (error) {
    yield put({ type: ITEMS.FAILURE, payload: error.message });
    const handled = yield handleUserSessionErrors(error);
    if (!handled)
      yield put(
        action(SHOW_NOTIFICATION, {
          className: NotificationTypes.ERROR,
          message: i18n.t('rewards.newBatch.error.message'),
        })
      );
  }
}

function* createBatch({ batchData, history }) {
  try {
    const response = yield call(postRequest, `/batch/`, batchData);

    yield put({ type: CREATE_BATCH.SUCCESS, payload: response.data });
    history.push(`${APP_ROUTES.BATCH_VIEW}/${response.data.batchId}`);
    yield put(
      action(SHOW_NOTIFICATION, {
        className: NotificationTypes.SUCCESS,
        description: i18n.t('rewards.newBatch.save.success.description'),
        message: i18n.t('rewards.newBatch.success.message'),
      })
    );
    return response.data.batchId;
  } catch (error) {
    yield put({ type: CREATE_BATCH.FAILURE, payload: error.message });
    const handled = yield handleUserSessionErrors(error);
    if (!handled)
      yield put(
        action(SHOW_NOTIFICATION, {
          description: i18n.t('rewards.newBatch.save.error.description'),
          className: NotificationTypes.ERROR,
          message: i18n.t('rewards.newBatch.error.message'),
        })
      );
    return null;
  }
}

function* updateBatchAsync({ batchId, data, redirectToBatchView, history, shouldAvoidBatchLoading }) {
  try {
    const response = yield call(putRequestCompressed, `/batch/${batchId}`, data);

    if (redirectToBatchView) yield loadBatchesAsync();

    if (redirectToBatchView) history.push('/suite/rewards');

    if (!shouldAvoidBatchLoading) yield loadBatchAsync({ selectedBatchId: batchId });

    yield put({ type: UPDATE_BATCH.SUCCESS, payload: response.data });
    yield put(
      action(SHOW_NOTIFICATION, {
        className: NotificationTypes.SUCCESS,
        message: i18n.t('rewards.batch.success.message'),
        description: i18n.t('rewards.batch.updated.success.description'),
      })
    );
  } catch (error) {
    yield put({ type: UPDATE_BATCH.FAILURE, payload: error.message });
    const handled = yield handleUserSessionErrors(error);
    if (!handled)
      yield put(
        action(SHOW_NOTIFICATION, {
          description: i18n.t('rewards.batch.updated.error.description'),
          className: NotificationTypes.ERROR,
          message: i18n.t('rewards.batch.error.message'),
        })
      );
    throw error;
  }
}

/**
 * update batch status and batch from Batch view
 * @param {payload} { batchStatus, batchData, history }
 */
function* saveAndUpdateBatchStatus({
  data: { batchId, batchStatus, comments, history, isBatchCreating, batchDataList, isMultipleBatchesSelected },
}) {
  const flowType = yield select(getRole);

  try {
    const selectedBatchId = yield select(getSelectedBatchId);

    let newBatchID;
    /** new batches are created only from Batch View Page
     * one at a time.
     * shouldSubmitCurrentRewardPoints is set to make sure that
     * current rewards points are always submitted for approval
     * by vendors in Batch View Page.
     *  */
    const newBatch = { shouldSubmitCurrentRewardPoints: true };
    if (!_.isEmpty(batchDataList) && batchDataList && !isMultipleBatchesSelected) {
      if (!isBatchCreating) {
        yield updateBatchAsync({ batchId, data: batchDataList[0], history });
        newBatch.batchId = batchId;
      } else {
        newBatchID = yield createBatch({ batchData: batchDataList[0], history });

        newBatch.batchId = newBatchID;
      }
      newBatch.comments = comments;
      newBatch.batchStatus = batchStatus;
    }

    const statusUpdatePayload = {
      data: {
        batchDataList: !isMultipleBatchesSelected ? [newBatch] : batchDataList,
      },
    };

    if (isMultipleBatchesSelected && (batchStatus === BatchStatus.APPROVED || batchStatus === BatchStatus.REJECTED)) {
      statusUpdatePayload.data.batchDataList = batchDataList.map((batch) => {
        return {
          batchId: batch.batchId,
          batchStatus,
        };
      });
    }

    yield updateBatchStatus(statusUpdatePayload);

    if (flowType === UserRole.SYSCO_ASSOCIATE && !isMultipleBatchesSelected)
      yield put({ type: BATCH.REQUEST, selectedBatchId });
    else {
      history.push('/suite/rewards');
      yield loadBatchesAsync();
    }
    yield put({ type: SAVE_UPDATE_BATCH_STATUS.SUCCESS, payload: statusUpdatePayload });
  } catch (error) {
    yield put({ type: SAVE_UPDATE_BATCH_STATUS.FAILURE, payload: error.message });
  }

  if (flowType === UserRole.SYSCO_ASSOCIATE && isMultipleBatchesSelected) yield put({ type: BATCHES.REQUEST });
}

function* loadOfferCodesAsync() {
  try {
    const { page, ...filtersApplied } = yield select(getOfferCodeFilters);

    const response = yield call(
      postRequest,
      `/offer-code/header/user?page=${page}&size=${BATCHES_PAGE_SIZE}`,
      filtersApplied
    );

    response.data.groupedOfferCodes = _.groupBy(response?.data?.items, 'rewardType');
    yield put({ type: OFFER_CODES.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: OFFER_CODES.FAILURE, payload: error.message });
    const handled = yield handleUserSessionErrors(error);
    if (!handled)
      yield put(
        action(SHOW_NOTIFICATION, {
          message: i18n.t('rewards.offerCodes.error.message'),
          description: i18n.t('rewards.offerCodes.error.description'),
          className: NotificationTypes.ERROR,
        })
      );
  }
}

function* loadAllNonExpiredOfferCodesAsync() {
  try {
    const filtersApplied = {
      sort: null,
      opco: 'All',
      status: 'NONEXPIRED',
      startDate: null,
      offerCodeId: null,
      endDate: null,
      rewardType: 'All',
    };

    const response = yield call(postRequest, `/offer-code/header/user?page=0&size=0`, filtersApplied);
    response.data.groupedOfferCodes = _.groupBy(response?.data?.items, 'rewardType');

    yield put({ type: OFFER_CODES.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: OFFER_CODES.FAILURE, payload: error.message });
    const handled = yield handleUserSessionErrors(error);
    if (!handled)
      yield put(
        action(SHOW_NOTIFICATION, {
          message: i18n.t('rewards.offerCodes.error.message'),
          description: i18n.t('rewards.offerCodes.error.description'),
          className: NotificationTypes.ERROR,
        })
      );
  }
}

function* loadOfferCodeAsync({ selectedOfferCodeId }) {
  try {
    const response = yield call(getRequest, `/offer-code/${selectedOfferCodeId}`);
    yield put({ type: OFFER_CODE.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: OFFER_CODE.FAILURE, error: error.message });

    const handled = yield handleUserSessionErrors(error);
    if (!handled) {
      let description = i18n.t('rewards.offerCode.error.description');

      if (error.response?.status === NOT_FOUND_STATUS)
        description = i18n.t('rewards.offerCode.notFound.error.description');
      yield put(
        action(SHOW_NOTIFICATION, {
          message: i18n.t('rewards.offerCode.error.message'),
          description,
          className: NotificationTypes.ERROR,
        })
      );
    }
  }
}
function* updateOfferCodeAsync({ data: { offerCodeId, endDate, comment } = {}, history }) {
  try {
    yield call(patchRequest, `/offer-code/${offerCodeId}/update`, {
      offerCodeId,
      endDate,
      comment,
    });
    yield put({ type: UPDATE_OFFER_CODE.SUCCESS });
    yield put(
      action(SHOW_NOTIFICATION, {
        className: NotificationTypes.SUCCESS,
        message: i18n.t('rewards.offerCode.success.message'),
        description: i18n.t('rewards.offerCode.updated.success.description'),
      })
    );

    history.push('/suite/rewards/offerCodes');
  } catch (error) {
    yield put({ type: UPDATE_OFFER_CODE.FAILURE, error: error.message });
    const handled = yield handleUserSessionErrors(error);
    if (!handled)
      yield put(
        action(SHOW_NOTIFICATION, {
          message: i18n.t('rewards.offerCode.error.message'),
          description: i18n.t('rewards.offerCode.updated.error.description'),
          className: NotificationTypes.ERROR,
        })
      );
  }
}

function* createOfferCodeAsync({ data }) {
  try {
    const { name: associateName, userId: associateId } = yield select(getUser);
    const response = yield call(postRequest, '/offer-code', { ...data, associateName, associateId });

    yield loadOfferCodesAsync();

    yield put({ type: CREATE_OFFER_CODE.SUCCESS, payload: response.data });
    yield put(
      action(SHOW_NOTIFICATION, {
        className: NotificationTypes.SUCCESS,
        message: i18n.t('rewards.offerCode.success.message'),
        description: i18n.t('rewards.offerCode.save.success.description'),
      })
    );
  } catch (error) {
    yield put({ type: CREATE_OFFER_CODE.FAILURE, error: error.message });

    const handled = yield handleUserSessionErrors(error);
    if (!handled) {
      let description = i18n.t('rewards.offerCode.save.error.description');

      if (error.response?.status === BAD_REQUEST_STATUS)
        description = i18n.t('rewards.offerCode.alreadyExists.error.description');
      yield put(
        action(SHOW_NOTIFICATION, {
          message: i18n.t('rewards.offerCode.error.message'),
          description,
          className: NotificationTypes.ERROR,
        })
      );
    }
  }
}
function* deleteBatchAsync({ selectedBatchId, redirectToBatchView, history }) {
  try {
    const data = yield call(deleteRequest, `/batch/${selectedBatchId}`);
    yield put({ type: DELETE_BATCH.SUCCESS, payload: data.batchData });
    yield loadBatchesAsync();
    yield put(
      action(SHOW_NOTIFICATION, {
        className: NotificationTypes.SUCCESS,
        message: i18n.t('rewards.batch.success.message'),
        description: i18n.t('rewards.batch.delete.success.description'),
      })
    );
    if (redirectToBatchView) history.push('/suite/rewards');
  } catch (error) {
    yield put({ type: DELETE_BATCH.FAILURE, error: error.message });
    const handled = yield handleUserSessionErrors(error);

    if (!handled) {
      yield put(
        action(SHOW_NOTIFICATION, {
          message: i18n.t('rewards.batch.error.message'),
          description:
            error.response?.status === NOT_FOUND_STATUS
              ? i18n.t('rewards.batch.notFound.error.description')
              : i18n.t('rewards.batch.delete.error.description'),
          className: NotificationTypes.ERROR,
        })
      );
    }
  }
}

function* updateFiltersAsync({ data }) {
  yield put({ type: UPDATE_FILTERS.SUCCESS, payload: data });
}

function* updateOfferCodeFiltersAsync({ data }) {
  yield put({ type: UPDATE_OFFER_CODE_FILTERS.SUCCESS, payload: data });
}
function* showNotificationAsync(notificationAction) {
  const { message, description, className, isClosable } = notificationAction;
  yield openNotification({ message, description, className, isClosable });
}

function* watchShowNotification() {
  yield takeEvery(SHOW_NOTIFICATION, showNotificationAsync);
}

function* watchLoadRewardItems() {
  yield takeLatest(ITEMS.REQUEST, loadRewardItems);
}

function* watchCreateBatch() {
  yield takeLatest(CREATE_BATCH.REQUEST, createBatch);
}

function* watchLoadUser() {
  yield takeLatest(USER.REQUEST, loadUserAsync);
}

function* watchLoadBatches() {
  yield takeLatest(BATCHES.REQUEST, loadBatchesAsync);
}

function* watchLoadBatch() {
  yield takeLatest(BATCH.REQUEST, loadBatchAsync);
}

function* watchUpdateBatch() {
  yield takeLatest(UPDATE_BATCH.REQUEST, updateBatchAsync);
}

function* watchSaveAndUpdateBatchStatus() {
  yield takeLatest(SAVE_UPDATE_BATCH_STATUS.REQUEST, saveAndUpdateBatchStatus);
}

function* watchUpdateFilters() {
  yield takeLatest(UPDATE_FILTERS.REQUEST, updateFiltersAsync);
}

function* watchUpdateOfferCodeFilters() {
  yield takeLatest(UPDATE_OFFER_CODE_FILTERS.REQUEST, updateOfferCodeFiltersAsync);
}
function* watchLoadOfferCodes() {
  yield takeLatest(OFFER_CODES.REQUEST, loadOfferCodesAsync);
}

function* watchLoadOfferCode() {
  yield takeLatest(OFFER_CODE.REQUEST, loadOfferCodeAsync);
}

function* watchUpdateOfferCode() {
  yield takeLatest(UPDATE_OFFER_CODE.REQUEST, updateOfferCodeAsync);
}
function* watchCreateOfferCode() {
  yield takeLatest(CREATE_OFFER_CODE.REQUEST, createOfferCodeAsync);
}

function* loadAllOfferCodes() {
  yield takeLatest(FETCH_ALL_OFFER_CODES.REQUEST, loadAllNonExpiredOfferCodesAsync);
}

function* watchDeleteBatch() {
  yield takeLatest(DELETE_BATCH.REQUEST, deleteBatchAsync);
}
export default function* rootSaga() {
  yield all([
    watchLoadUser(),
    watchLoadBatches(),
    watchLoadBatch(),
    watchLoadRewardItems(),
    watchUpdateBatch(),
    watchSaveAndUpdateBatchStatus(),
    watchUpdateFilters(),
    watchShowNotification(),
    watchLoadOfferCodes(),
    watchLoadOfferCode(),
    watchCreateBatch(),
    watchUpdateOfferCode(),
    watchUpdateOfferCodeFilters(),
    watchCreateOfferCode(),
    loadAllOfferCodes(),
    watchDeleteBatch(),
  ]);
}
