import React from 'react';
import { BatchStatus, UserRole } from 'enums';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import DeleteModal from 'components/DeleteModalHolder';
import useModalToggle from 'hooks/useModalToggle';

export default function HoverActions({ userRole, batchData, approveBatch, rejectBatch, onDelete }) {
  const enableHoverButtons = userRole === UserRole.SYSCO_ASSOCIATE && batchData?.batchStatus === BatchStatus.REVIEWED;
  const enableHoverDeleteButton =
    userRole === UserRole.MASTER_VENDOR &&
    (batchData?.batchStatus === BatchStatus.OPEN || batchData?.batchStatus === BatchStatus.PENDING);
  const { t } = useTranslation();
  const [isDeleteBatchModalVisible, onToggleDeleteBatchModalVisible] = useModalToggle();

  return (
    <div id="hover-actions-panel">
      {enableHoverButtons && (
        <div id="hover-action-controls" className="approver-control grid-hover-panel">
          <button
            id="batch-merchandiser-approve"
            type="button"
            className="ant-btn btn green-action-btn ant-btn-primary"
            onClick={() => {
              approveBatch(batchData);
            }}
          >
            <i className="fi flaticon-tick-1"></i>
            <span id="batch-merchandiser-approve-label">{t(`rewards.approve`)}</span>
          </button>
          <button
            id="batch-merchandiser-reject"
            type="button"
            className="ant-btn btn red-action-btn ant-btn-primary"
            onClick={() => {
              rejectBatch(batchData);
            }}
          >
            <i className="fi flaticon-round-delete-button"></i>
            <span id="batch-merchandiser-reject-label">{t(`rewards.reject`)}</span>
          </button>
        </div>
      )}
      {enableHoverDeleteButton && (
        <div id="hover-action-controls" className="approver-control grid-hover-panel">
          <React.Fragment>
            <Button
              type="primary"
              className="btn delete-btn red-action-btn invert icon-only unrounded"
              onClick={onToggleDeleteBatchModalVisible}
            >
              <i className="fi flaticon-delete"></i>
            </Button>
            <DeleteModal
              visible={isDeleteBatchModalVisible}
              onToggle={onToggleDeleteBatchModalVisible}
              batchData={batchData || null}
              onDelete={onDelete}
            />
          </React.Fragment>
        </div>
      )}
    </div>
  );
}
