import * as _ from 'lodash';

const adPrefix = process.env.REACT_APP_AD_PREFIX;
const oktaPrefix = process.env.REACT_APP_OKTA_PREFIX;

const getSyscoUserId = (username) => {
  if (username.indexOf(adPrefix) >= 0) {
    return _.split(username, '@')[0].replace(adPrefix, '');
  }
  if (username.indexOf(oktaPrefix) >= 0) {
    return username.replace(oktaPrefix, '');
  }
  return username;
};

export default getSyscoUserId;
