import { Button, Modal } from 'antd';
import OfferCodeForm from 'components/OfferCodeForm';
import OfferCodesListPageContext from 'context/OfferCodesListContext';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

function CreateOfferCodeModal({ visible, toggleModal }) {
  const { t } = useTranslation();

  const { createOfferCode, isOfferCodeFormValid, resetOfferCodeForm } = useContext(OfferCodesListPageContext);

  const onClickCreateButton = useCallback(() => {
    if (createOfferCode) createOfferCode();
    if (toggleModal && isOfferCodeFormValid()) toggleModal();
  }, [toggleModal, createOfferCode, isOfferCodeFormValid]);

  const closeModal = useCallback(() => {
    if (resetOfferCodeForm) resetOfferCodeForm();
    toggleModal();
  }, [toggleModal, resetOfferCodeForm]);

  return (
    <Modal
      title=""
      centered
      visible={visible}
      onOk={closeModal}
      onCancel={closeModal}
      width={700}
      footer={null}
      className="create-modal"
    >
      <div id="create-offer-code-modal" className="">
        <div id="create-offer-code-modal-header" className="ant-modal-header">
          <div className="ant-modal-title" id="rcDialogTitle0">
            {t(`rewards.offerCode.createOfferCode`)}
          </div>
        </div>
        <div id="create-offer-code-modal-body" className="ant-modal-create-body">
          <div id="create-offer-code-modal-contents" className="create-modal-contents create-offer-code-contents">
            <div id="create-offer-code-modal-left-panel" className="left-panel">
              <OfferCodeForm />
            </div>
          </div>
          <div id="create-offer-code-modal-footer" className="ant-modal-footer">
            <div>
              <Button
                id="create-offer-cancel-button"
                className="ant-btn btn white-action-btn no-shadow no-flex"
                onClick={closeModal}
              >
                <span id="create-offer-cancel-label">{t(`rewards.cancel`)}</span>
              </Button>
              <Button
                id="create-offer-confirm-button"
                className="ant-btn btn green-action-btn no-flex continue-btn ant-btn-primary"
                onClick={onClickCreateButton}
              >
                <span id="create-offer-confirm-button-label">{t(`rewards.create`)}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CreateOfferCodeModal;
