import React, { useEffect } from 'react';
import { Provider } from 'react-redux';

import store from 'store';
import i18n from './_i18n';
import MainApp from './MainApp';

function App(props) {
  const {
    language,
    Auth: {
      user: { username },
    },
    setUserRole,
  } = props;

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <Provider store={store}>
      <MainApp userId={username} setUserRole={setUserRole} />
    </Provider>
  );
}

export default App;
