import React, { useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Select } from 'antd';

import * as rewardsActions from 'actions';
import { APP_ROUTES } from 'util/constants';
import { NotificationTypes, RewardType, OfferCodeStatus } from 'enums';
import { OfferCodesListPageContextProvider } from 'context/OfferCodesListContext';
import { OfferCodeFormContextProvider } from 'context/OfferCodeFormContext';
import OfferCodesListPageComponent from './components/OfferCodesListPageComponent';

const { Option } = Select;

const newOfferCode = {
  rewardType: RewardType.LOYALTY,
  offerCodeId: '',
  startDate: null,
  endDate: null,
  comment: '',
};

function OfferCodesListPageContainer({
  actions,
  offerCodes,
  userRole,
  offerCodeFilters,
  offerCodesFetching,
  isOfferCodeCreating,
  totalOfferCodes,
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const { search } = useLocation();
  const { codeId } = queryString.parse(search);

  const offerCodeFormRef = useRef();

  useEffect(() => {
    actions.loadOfferCodesDetails();
  }, [actions, offerCodeFilters]);

  const navigateToBatchesList = useCallback(() => {
    history.push(APP_ROUTES.BATCHES_LIST);
  }, [history]);

  const viewOfferCode = useCallback(
    (offerCodeId) => {
      if (offerCodeId) history.push(`${APP_ROUTES.OFFER_CODE_VIEW}/${offerCodeId}`);
      else
        actions.showNotification(
          t('rewards.offerCode.error.noId.message'),
          t('rewards.offerCode.error.noId.description'),
          NotificationTypes.ERROR
        );
    },
    [history, actions]
  );
  const onChangePage = (current) => {
    if (current !== offerCodeFilters.page) actions.updateOfferCodeFilters({ ...offerCodeFilters, page: current });
  };

  const getTypeSource = () => {
    return _.values(RewardType).map((item) => <Option key={item}>{item}</Option>);
  };

  const getStatusSource = () => {
    return _.values(OfferCodeStatus).map((item) => (
      <Option key={item} value={item}>
        {item}
      </Option>
    ));
  };

  const handleTypeFilterChange = useCallback(
    (rewardType) => {
      const query = { ...offerCodeFilters, rewardType };
      actions.updateOfferCodeFilters(query);
    },
    [actions, offerCodeFilters]
  );

  const handleStatusFilterChange = useCallback(
    (status) => {
      const query = { ...offerCodeFilters, status };
      actions.updateOfferCodeFilters(query);
    },
    [actions, offerCodeFilters]
  );

  const onSearchOfferCodeId = (e) => {
    if (e.keyCode === 13) {
      const searchedId = e.target.value;
      if (searchedId) {
        history.push({
          pathname: APP_ROUTES.OFFER_CODES_LIST,
          search: `?codeId=${searchedId?.trim()}`,
        });
      } else {
        history.push(APP_ROUTES.OFFER_CODES_LIST);
      }
    }
  };

  const onGetSearchKeyword = (batchIdString) => {
    let dataSearch;
    const offerCodeIdValue = batchIdString?.trim();
    if (offerCodeIdValue?.length) {
      dataSearch = { ...offerCodeFilters, offerCodeId: offerCodeIdValue, page: 1 };
    } else {
      dataSearch = { ...offerCodeFilters, offerCodeId: null, page: 1 };
    }
    actions.updateOfferCodeFilters(dataSearch);
  };

  useEffect(() => {
    onGetSearchKeyword(codeId);
  }, [codeId]);

  const onCreateOfferCode = useCallback(
    () => actions.createOfferCode(offerCodeFormRef.current?.values),
    [offerCodeFormRef, actions]
  );

  const createOfferCode = useCallback(() => offerCodeFormRef.current?.submitForm(), [offerCodeFormRef]);

  const resetOfferCodeForm = useCallback(
    () => offerCodeFormRef.current?.resetForm({ values: newOfferCode }),
    [offerCodeFormRef]
  );

  const isOfferCodeFormValid = () => offerCodeFormRef.current?.isValid;

  const pageContextValue = {
    offerCodes,
    totalOfferCodes,
    userRole,
    offerCodeFilters,
    offerCodesFetching,
    navigateToBatchesList,
    viewOfferCode,
    handleTypeFilterChange,
    handleStatusFilterChange,
    onChangePage,
    createOfferCode,
    resetOfferCodeForm,
    isOfferCodeFormValid,
    isOfferCodeCreating,
    typeSource: getTypeSource,
    statusSource: getStatusSource,
    onSearchOfferCodeId,
    offerCodeId: codeId,
  };

  const createOfferCodeContextValue = {
    offerCodeFormRef,
    onSave: onCreateOfferCode,
    offerCode: newOfferCode,
  };

  return (
    <OfferCodesListPageContextProvider value={pageContextValue}>
      <OfferCodeFormContextProvider value={createOfferCodeContextValue}>
        <OfferCodesListPageComponent />
      </OfferCodeFormContextProvider>
    </OfferCodesListPageContextProvider>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user.data,
    offerCodes: state.offerCodes.data.items,
    userRole: state.user.data.userRole,
    offerCodesFetching: state.offerCodes.fetching,
    offerCodeFilters: state.offerCodeFilters.data,
    totalOfferCodes: state.offerCodes.data.totalItems,
    isOfferCodeCreating: state.createOfferCode.fetching,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(rewardsActions, dispatch) };
}

OfferCodesListPageContainer.propTypes = {
  offerCodes: PropTypes.object,
  actions: PropTypes.any,
  userRole: PropTypes.string,
  offerCodesFetching: PropTypes.bool,
  offerCodeFilters: PropTypes.object,
  totalOfferCodes: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferCodesListPageContainer);
