import React, { useCallback } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import CreateBatchForm from './CreateBatchForm';

function CreateBatchModal({
  closeCreateModal,
  visible,
  onCreateBatchDetails,
  opcos,
  createBatchFormRef,
  handleBatchDetailsSubmit,
}) {
  const { t } = useTranslation();

  const onContinue = useCallback(() => {
    if (closeCreateModal) closeCreateModal();
    if (handleBatchDetailsSubmit) handleBatchDetailsSubmit();
  }, []);

  return (
    <Modal
      id="batch-create-modal"
      title=""
      centered
      visible={visible}
      onCancel={closeCreateModal}
      width={700}
      footer={null}
      className="create-modal"
    >
      <div id="batch-create-modal-section" className="">
        <div id="batch-create-modal-header" className="ant-modal-header">
          <div className="ant-modal-title" id="rcDialogTitle0">
            {t('rewards.createBatch.createRewards')}
          </div>
        </div>
        <div id="batch-create-modal-body" className="ant-modal-create-body">
          <CreateBatchForm
            createBatchFormRef={createBatchFormRef}
            onCreateNewBatch={onCreateBatchDetails}
            opcoList={opcos}
          />
          <div id="batch-create-modal-footer" className="ant-modal-footer">
            <div>
              <button
                id="popup-cancel-button"
                type="button"
                className="ant-btn btn white-action-btn no-shadow no-flex"
                ant-click-animating-without-extra-node="false"
                onClick={closeCreateModal}
              >
                <span id="batch-create-modal-cancel-label">{t('rewards.cancel')}</span>
              </button>
              <button
                id="popup-confirm-button"
                disabled=""
                type="button"
                className="ant-btn btn green-action-btn no-flex continue-btn ant-btn-primary"
                onClick={onContinue}
              >
                <span id="batch-create-modal-continue-label">{t('rewards.createBatch.continue')}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CreateBatchModal;
