import { BatchDownloadNames, RewardType } from 'enums';

/**
 * Returns the correct component for filename
 * @param {any} component A batchId or regionName (can be null/ undefined)
 * @returns {String} If null/ undefined component, returns empty strings or else the component
 */
const getComponentForFileName = (component) => {
  return component || '';
};

/**
 * Returns the constructed filename
 * @param {Array} components A list of components of a filename
 * @returns {String} constructed file name by joining components by underscore
 */
const constructFileName = (components) => {
  return components.filter((component) => component !== '').join('_');
};

/**
 * Create csv downloadable file name
 * @param {any} selectedBatch batch data
 * @returns {any} generated CSV FileNames
 */
export default function generateCSVFilename(selectedBatch) {
  let components;

  switch (selectedBatch?.rewardType) {
    case RewardType.SEASONAL:
      components = [getComponentForFileName(selectedBatch?.batchId), BatchDownloadNames.SEASONAL];
      break;

    // case RewardType.GROWTH:
    //   components = [getComponentForFileName(selectedBatch?.batchId), BatchDownloadNames.GROWTH];
    //   break;

    case RewardType.LOYALTY:
      components = [getComponentForFileName(selectedBatch?.batchId), BatchDownloadNames.LOYALTY];
      break;

    default:
      return '';
  }
  return constructFileName(components);
}
