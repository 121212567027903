import { OfferCodeStatus, RewardType } from 'enums';
import React from 'react';

const OfferCodeFormContext = React.createContext({
  offerCodeFormRef: null,
  offerCode: {
    rewardType: RewardType.LOYALTY,
    offerCodeId: '',
    startDate: '',
    endDate: '',
    comment: '',
    status: OfferCodeStatus.ACTIVE,
  },
  onSave: null,
  isEditing: false,
});

export const OfferCodeFormContextProvider = OfferCodeFormContext.Provider;

export default OfferCodeFormContext;
