import React from 'react';
import { Button, Table, Select } from 'antd';
import ReactResizeDetector from 'react-resize-detector';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';

import { RewardType, UserRole } from 'enums';
import useModalToggle from 'hooks/useModalToggle';
import { BATCHES_PAGE_SIZE, FILTER_ALL } from 'util/constants';
import RewardsTabs from 'components/RewardsTabs';
import CreateBatchPopUp from './CreateBatchModal';
import MassUpdateSelector from './MassUpdateSelector';

const { Option } = Select;

const validRewardTypes = _.values(RewardType);

export default function BatchesPageComponent({
  actions,
  batchesFetching,
  filters,
  opcoDetails,
  statusSource,
  onChangeRewardType,
  onChangeStatusType,
  rowSelection,
  columns,
  data,
  batchItems,
  onResize,
  onCreateBatchDetails,
  onChangePage,
  onSearchBatchId,
  userRole,
  navigateToOfferCodesList,
  createBatchFormRef,
  handleBatchDetailsSubmit,
  multipleSelectorVisible,
  offerCodeList,
  selectedItems,
  commonOfferCodeIds,
  updateOfferCode,
  onChangeVendorType,
  vendorList,
  approveBatches,
  rejectBatches,
  gridScrollHeight,
  isSameRewardTypeBatches,
  getMatchedOfferCodes,
}) {
  const { t } = useTranslation();
  const [createBatchModalVisible, toggleCreateBatchModal] = useModalToggle();
  return (
    <>
      <div id="filter-panel-grid" className="grid-filter-panel">
        <RewardsTabs userRole={userRole} navigateToOfferCodesList={navigateToOfferCodesList} />

        <div id="filter-panel-grid-title-batches" className="title reward">
          {t('rewards.batches')}
        </div>

        <div id="filter-panel-grid-batches-search" className="filter batch-search-wrap">
          <span id="filter-panel-grid-batches-search-input-group" className="ant-input-wrapper ant-input-group">
            <span className="ant-input-search ant-input-search-enter-button ant-input-affix-wrapper">
              <div id="filter-panel-grid-batches-search-wrap" className="filter batch-search-wrap">
                <input
                  placeholder={t('rewards.batch.id')}
                  type="search"
                  className="search"
                  id="batches-search"
                  onKeyDown={onSearchBatchId}
                  defaultValue={filters.batchId}
                  disabled={batchesFetching}
                />
                <Button type="link" className="advanced-search-link" />
              </div>
            </span>
          </span>
        </div>

        {userRole === UserRole.MASTER_VENDOR && (
          <button
            id="create-new-btn"
            type="button"
            className="ant-btn btn create-new-btn ant-btn-primary"
            onClick={toggleCreateBatchModal}
          >
            <i className="fi flaticon-add-button-inside-black-circle"></i>
            <span>{t('rewards.add.rewards')}</span>
          </button>
        )}

        <div className="spacer"></div>
        <div id="filter-operations-section" className="filters">
          {userRole === UserRole.SYSCO_ASSOCIATE && (vendorList?.length || 0) > 1 && (
            <div id="vendor-filter" className="filter">
              <label id="vendor-filter-label" htmlFor="masterVendorFilter">
                {t('rewards.vendor')}
              </label>
              <Select
                id="masterVendorFilter"
                className="filter-search"
                style={{ width: '100%' }}
                onChange={onChangeVendorType}
                value={_.isEmpty(filters.vendorIds) || filters.vendorIds.length > 1 ? FILTER_ALL : filters.vendorIds[0]}
                disabled={batchesFetching}
                dropdownMatchSelectWidth={false}
              >
                <Option id="master-vendor-filter-all" key={FILTER_ALL} value={FILTER_ALL}>
                  {t('rewards.all')}
                </Option>
                {vendorList?.map((vendor, i) => (
                  <Option
                    id={`master-vendor-${i + 1}-filter`}
                    style={{ width: 'max-content' }}
                    key={vendor.vendorId}
                    value={vendor.vendorId}
                  >
                    {`${vendor.vendorId}-${vendor.vendorName}`}
                  </Option>
                ))}
              </Select>
            </div>
          )}

          <div id="batch-status-filter" className="filter">
            <label id="batch-status-filter-label" htmlFor="batchStatusFilter">
              {t('rewards.status')}
            </label>
            <Select
              id="batchStatusFilter"
              className="filter-select"
              dropdownMatchSelectWidth={false}
              onChange={onChangeStatusType}
              value={filters.batchStatus}
              disabled={batchesFetching}
              style={{ minWidth: '8rem' }}
            >
              <Option id="batch-status-filter-all" key={FILTER_ALL} value={FILTER_ALL}>
                {t('rewards.all')}
              </Option>
              {statusSource().map((batchStatus, i) => (
                <Option id={`batch-status-${i + 1}-filter`} key={batchStatus} value={batchStatus}>
                  {batchStatus}
                </Option>
              ))}
            </Select>
          </div>
          <div id="rewards-type-filter" className="filter">
            <label id="rewards-type-filter-label" htmlFor="rewardsTypeFilter">
              {t('rewards.reward')}
            </label>
            <Select
              id="rewardsTypeFilter"
              className="filter-select"
              onChange={onChangeRewardType}
              dropdownMatchSelectWidth={false}
              value={filters.rewardType}
              disabled={batchesFetching}
              style={{ minWidth: '8rem' }}
            >
              <Option id="rewards-type-filter-all" key={FILTER_ALL} value={FILTER_ALL}>
                {t('rewards.all')}
              </Option>
              {validRewardTypes.map((type, i) => (
                <Option id={`rewards-type-${i + 1}-filter`} key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>

      <ReactResizeDetector handleHeight onResize={onResize}>
        <div id="rewards-batches-table-panel" className="grid-panel">
          <Table
            id="rewards-batches-table"
            className="costing-batches-table"
            rowKey="batchId"
            rowSelection={userRole === UserRole.SYSCO_ASSOCIATE ? rowSelection : false}
            loading={batchesFetching}
            pagination={{
              defaultPageSize: BATCHES_PAGE_SIZE,
              current: filters.page,
              total: batchItems,
              onChange: onChangePage,
            }}
            columns={columns}
            dataSource={data}
            scroll={{ x: '100%', y: gridScrollHeight }}
          />
          {multipleSelectorVisible && userRole === UserRole.SYSCO_ASSOCIATE && (
            <MassUpdateSelector
              updateOfferCode={updateOfferCode}
              offerCodeList={offerCodeList}
              selectedItems={selectedItems}
              actions={actions}
              commonOfferCodeIds={commonOfferCodeIds}
              batchesFetching={batchesFetching}
              approveBatches={approveBatches}
              rejectBatches={rejectBatches}
              isSameRewardTypeBatches={isSameRewardTypeBatches}
              getMatchedOfferCodes={getMatchedOfferCodes}
            />
          )}
        </div>
      </ReactResizeDetector>
      <CreateBatchPopUp
        visible={createBatchModalVisible}
        closeCreateModal={toggleCreateBatchModal}
        opcos={opcoDetails}
        onCreateBatchDetails={onCreateBatchDetails}
        createBatchFormRef={createBatchFormRef}
        handleBatchDetailsSubmit={handleBatchDetailsSubmit}
      />
    </>
  );
}
