import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import OfferCodeViewPageContext from 'context/OfferCodeViewContext';
import * as _ from 'lodash';

function OfferCodeHeader() {
  const { t } = useTranslation();
  const {
    updateOfferCode,
    navigateToOfferCodeList,
    offerCode: { offerCodeId } = {},
  } = useContext(OfferCodeViewPageContext);

  return (
    <div id="offer-code-header" className="grid-filter-panel">
      <div id="offer-code-header-title" className="title reward">
        {t(`rewards.offerCode`)}
      </div>
      <button
        id="offer-code-merchandiser-update"
        type="button"
        className="ant-btn btn green-action-btn ant-btn-primary"
        onClick={updateOfferCode}
        disabled={_.isEmpty(offerCodeId)}
      >
        <span>{t(`rewards.update`)}</span>
      </button>
      <button
        id="offer-code-back-to-list"
        type="button"
        className="ant-btn btn back-btn ant-btn-link"
        onClick={navigateToOfferCodeList}
      >
        <i className="fi flaticon-arrow"></i>
        <span>{t(`rewards.back`)}</span>
      </button>
    </div>
  );
}

export default OfferCodeHeader;
