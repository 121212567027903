import React, { useState } from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

const CommentHolder = ({ saveComment, setDropdownVisible }) => {
  const { t } = useTranslation();
  const [commentInput, setCommentInput] = useState('');

  return (
    <div id="comment-holder" className="rewards-note">
      <div id="comment-holder-display-text" className="type">
        {t('rewards.commentHolder.enterNotes')}
      </div>
      <Input
        id="comment-input"
        value={commentInput}
        size="default"
        placeholder={t('rewards.comments')}
        onChange={({ target: { value } }) => {
          setCommentInput(value);
        }}
        onFocus={() => setDropdownVisible(true)}
      />
      <div className="note-content-reward-new-comment">
        <button
          id="comment-add-btn"
          disabled=""
          type="button"
          className="ant-btn btn ant-btn-primary"
          onClick={() => {
            saveComment(commentInput);
            setCommentInput('');
          }}
        >
          <span id="comment-add-label">{t('rewards.add')}</span>
        </button>
        <button
          id="comment-cancel-btn"
          type="button"
          className="ant-btn btn cancel-btn ant-btn-default"
          onClick={() => {
            setDropdownVisible(false);
            setCommentInput('');
          }}
        >
          <span id="comment-cancel-label">{t('rewards.cancel')}</span>
        </button>
      </div>
    </div>
  );
};

export default CommentHolder;
