import React, { useState } from 'react';
import { Dropdown, Menu } from 'antd';
import moment from 'moment';
import * as _ from 'lodash';

import { UserRole } from 'enums';
import { COMMENT_TIME_PATTERN } from 'util/constants';
import Comment from './Comment';
import CommentHolder from './CommentHolder';

function CommentsDropdown({ comments, userRole, saveComment }) {
  const isSyscoAssociate = userRole === UserRole.SYSCO_ASSOCIATE;

  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const menu = (
    <Menu id="comments-drop-down-menu" className="comments-menu">
      {isSyscoAssociate && (
        <Menu.Item>
          <CommentHolder saveComment={saveComment} setDropdownVisible={setDropdownVisible} />
        </Menu.Item>
      )}

      {comments?.map((comment) => {
        return (
          <Menu.Item>
            <Comment
              id={`comment-${comment?.postedTime + comment?.authorId}`}
              key={comment?.postedTime + comment?.authorId}
              title={comment?.authorName}
              content={comment?.text}
              dateTime={moment(comment?.postedTime).format(COMMENT_TIME_PATTERN)}
            />
          </Menu.Item>
        );
      })}
    </Menu>
  );
  return isSyscoAssociate || !_.isEmpty(comments) ? (
    <Dropdown
      overlay={menu}
      visible={isDropdownVisible}
      onBlur={() => setDropdownVisible(false)}
      onFocus={() => setDropdownVisible(true)}
      tabIndex="0"
    >
      <button
        id="comment-drop-down-btn"
        type="button"
        className="ant-btn btn icon-only notes-btn ant-btn-link notes-btn-editreward"
      >
        <span className="ant-badge">
          <i className="fi flaticon-comment"></i>
          {!_.isEmpty(comments) && <sup data-show="true" className="ant-scroll-number ant-badge-dot"></sup>}
        </span>
      </button>
    </Dropdown>
  ) : null;
}

export default CommentsDropdown;
