import React, { useCallback, useState } from 'react';
import { Field, Formik } from 'formik';
import { Input, Table } from 'formik-antd';
import { Table as AntdTable, Tooltip, Checkbox, Select, Icon } from 'antd';
import ReactResizeDetector from 'react-resize-detector';
import * as _ from 'lodash';

import {
  BATCHES_PAGE_SIZE,
  FILTER_ALL,
  ITEM_SEARCH_INDICES,
  MAX_REWARD_POINTS,
  MIN_REWARD_POINTS,
} from 'util/constants';
import i18n from '_i18n';
import handlePageLeave from 'util/handlePageLeave';
import RewardPoints from 'components/Badges/RewardPoints';
import { BatchStatus, UserRole } from 'enums';
import CommentsDropdown from 'components/CommentsDropdown';
import compareObjectsByField from 'util/compareObjectsByField';
import { SYSCO_LIGHT_BLUE, SYSCO_LIGHT_GREEN } from 'util/colors';
import getCurrentItemRewardsValue from 'util/getCurrentItemRewardsValue';

const { Option } = Select;

const validateRewardPoints = (value) => {
  let error;
  const isValid =
    _.chain(value).toNumber().isNumber().value() && value >= MIN_REWARD_POINTS && value <= MAX_REWARD_POINTS;

  if (!isValid) {
    error = 'rewards.errors.newRewardPoints';
  }

  return error;
};

const getRewardsPointsStyle = (item) => {
  const currentRewardsValue = getCurrentItemRewardsValue(item);
  const isNewValueHigher = currentRewardsValue < item.newRewardPoints;
  const isNewValueLower = currentRewardsValue > item.newRewardPoints;

  const style = {};

  if (isNewValueHigher) style.backgroundColor = SYSCO_LIGHT_GREEN;
  if (isNewValueLower) style.backgroundColor = SYSCO_LIGHT_BLUE;

  return style;
};

const renderCurrentRewardsPoints = (text, item) => ({
  props: {
    style: getRewardsPointsStyle(item),
  },
  children: <RewardPoints placeholder={text} />,
});

const getColumns = (
  setFieldValue,
  batchStatus,
  userRole,
  handleItemOfferCodeChange,
  onItemCommentsChange,
  handleItemRejection,
  activeOfferCodes
) => {
  let columns = [
    {
      title: i18n.t('rewards.item.productInfo'),
      dataIndex: 'description',
      key: 'description',
      fixed: 'left',
      className: 'batch-section-header',
      children: [
        {
          title: i18n.t('rewards.batchItem.description'),
          dataIndex: 'description',
          key: 'description',
          width: 120,
          sorter: compareObjectsByField('description'),
          sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
          title: i18n.t('rewards.batchItem.brand'),
          dataIndex: 'brand',
          key: 'brand',
          width: 100,
          sorter: compareObjectsByField('brand'),
          sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
          title: i18n.t('rewards.batchItem.pack'),
          dataIndex: 'pack',
          key: 'pack',
          width: 100,
          sorter: compareObjectsByField('pack'),
          sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
          title: i18n.t('rewards.batchItem.size'),
          dataIndex: 'size',
          key: 'size',
          width: 100,
          sorter: compareObjectsByField('size'),
          sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
          title: i18n.t('rewards.batchItem.supc'),
          dataIndex: 'supc',
          key: 'supc',
          width: 100,
          sorter: compareObjectsByField('supc'),
          sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
          title: i18n.t('rewards.item.mfg'),
          dataIndex: 'mfgProductNumber',
          key: 'mfgProductNumber',
          width: 100,
          sorter: compareObjectsByField('mfgProductNumber'),
          sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
          title: i18n.t('rewards.batchItem.gtin'),
          dataIndex: 'gtin',
          key: 'gtin',
          width: 100,
          sorter: compareObjectsByField('gtin'),
          sortDirections: ['ascend', 'descend', 'ascend'],
        },
      ],
    },
    {
      dataIndex: 'opcoPoint',
      key: 'opcoPoint',
      className: 'batch-section-header opco-divider',
      children: [
        {
          title: i18n.t('rewards.item.currentLoyaltyPoints'),
          dataIndex: 'loyaltyPoints',
          key: 'loyaltyPoints',
          width: 120,
          render: renderCurrentRewardsPoints,
        },
        // {
        //   title: i18n.t('rewards.item.currentGrowthPoints'),
        //   dataIndex: 'growthPoints',
        //   key: 'growthPoints',
        //   width: 120,
        //   render: (text) => <RewardPoints placeholder={text} />,
        // },
        {
          title: i18n.t('rewards.item.currentSeasonalPoints'),
          dataIndex: 'seasonalPoints',
          key: 'seasonalPoints',
          width: 120,
          className: 'opco-divider',
          render: renderCurrentRewardsPoints,
        },
      ],
    },
    {
      title: i18n.t('rewards.nationalRewardPoints'),
      dataIndex: 'opcos',
      key: 'opcos',
      className: 'batch-section-header ',
      children: [
        {
          title: i18n.t('rewards.item.newRewards'),
          dataIndex: 'newRewardPoints',
          key: 'newRewardPoints',
          width: 'auto',
          // always move both current and new rewards points
          // zero item to bottom
          sorter: (a, b) =>
            (a.newRewardPoints || getCurrentItemRewardsValue(a)) && (b.newRewardPoints || getCurrentItemRewardsValue(b))
              ? a.newRewardPoints - b.newRewardPoints
              : -Infinity,
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div style={{ padding: '10px' }}>
              <Checkbox
                onChange={() => {
                  setSelectedKeys(!selectedKeys[0] ? [!selectedKeys[0]] : []);
                  confirm();
                }}
                checked={selectedKeys[0]}
              >
                {i18n.t('rewards.item.showUpdatedItems')}
              </Checkbox>
            </div>
          ),
          onFilter: (value, item) => !value || getCurrentItemRewardsValue(item) !== item?.newRewardPoints,
          render: (text, item) => {
            const currentRewardsValue = getCurrentItemRewardsValue(item);
            const isNewValueHigher = currentRewardsValue < item.newRewardPoints;
            const isNewValueLower = currentRewardsValue > item.newRewardPoints;

            return {
              props: {
                style: getRewardsPointsStyle(item),
              },
              children: (
                <div className="new-rewards-wrapper">
                  <Field
                    id={`product-list-${item.key}-new-reward-points`}
                    name={`${item.key}.newRewardPoints`}
                    value={text}
                    onChange={({ target: { value } }) => {
                      setFieldValue(`${item.key}.newRewardPoints`, _.toNumber(value) || '');
                    }}
                    validate={validateRewardPoints}
                  >
                    {({ field, form: { errors } }) => {
                      return (
                        <Tooltip
                          title={
                            errors[item.key]
                              ? i18n.t(errors[item.key]?.newRewardPoints, { MIN_REWARD_POINTS, MAX_REWARD_POINTS })
                              : undefined
                          }
                        >
                          <Input
                            {...field}
                            id={`product-list-${item.key}-new-reward-points-input`}
                            className={`location-point-input ${errors[item.key] && 'editable-cell-error'}`}
                            size="small"
                            type="number"
                            disabled={batchStatus !== BatchStatus.OPEN}
                            min={0}
                            max={MAX_REWARD_POINTS}
                          />
                        </Tooltip>
                      );
                    }}
                  </Field>
                  {isNewValueHigher && <Icon type="arrow-up" className="rewards-arrow" />}
                  {isNewValueLower && <Icon type="arrow-down" className="rewards-arrow" />}
                </div>
              ),
            };
          },
        },
      ],
    },
  ];

  const isItemsRejectable = userRole === UserRole.SYSCO_ASSOCIATE && batchStatus === BatchStatus.REVIEWED;

  const showItemRejections =
    userRole === UserRole.SYSCO_ASSOCIATE ||
    (userRole === UserRole.MASTER_VENDOR &&
      (batchStatus === BatchStatus.APPROVED ||
        batchStatus === BatchStatus.REJECTED ||
        batchStatus === BatchStatus.PARTIAL));

  if (showItemRejections) {
    columns = columns.concat([
      {
        title: i18n.t('rewards.reject'),
        dataIndex: 'rejectItem',
        key: 'rejectItem',
        width: '120',
        render: (text, item) => [
          <Checkbox
            id={`product-list-${item.key}-reject-item`}
            disabled={!isItemsRejectable}
            className="red-checkbox"
            checked={item?.rejectItem?.rejected}
            onChange={({ target: { checked } }) => {
              handleItemRejection(item, checked, setFieldValue);
            }}
          />,
        ],
      },
      {
        title: i18n.t('rewards.notes'),
        dataIndex: 'comments',
        key: 'comments',
        width: 'auto',
        render: (text, item) => [
          <CommentsDropdown
            id={`product-list-${item.key}-comments-drop-down`}
            userRole={userRole}
            comments={item?.comments}
            saveComment={(comment) => {
              onItemCommentsChange(item, comment, setFieldValue);
            }}
          />,
        ],
      },
    ]);

    const showOfferCodes = userRole === UserRole.SYSCO_ASSOCIATE;

    if (showOfferCodes) {
      columns[0].children.push({
        title: i18n.t('rewards.batchItem.offerCode'),
        dataIndex: 'offerCodeIds',
        key: 'offerCodeIds',
        width: 200,
        render: (offerCodeIds, item) => {
          return [
            <Select
              id={`product-list-${item.key}-offer-codes`}
              placeholder={i18n.t('rewards.pleaseSelect')}
              style={{ width: '100%', maxHeight: '64px', overflow: 'auto' }}
              value={offerCodeIds}
              onChange={async (offerCode) => {
                await setFieldValue(`${item.key}.offerCodeIds`, [offerCode]);
                handleItemOfferCodeChange();
              }}
              className="code-multi-select"
              disabled={!isItemsRejectable}
            >
              {activeOfferCodes?.map((offerCodeId) => {
                return (
                  <Option id={`product-list-${item.key}-offer-codes-${offerCodeId}`} key={offerCodeId}>
                    {offerCodeId}
                  </Option>
                );
              })}
            </Select>,
          ];
        },
      });
    }
  }

  return columns;
};

const filterItemsBasedOnKeywordAndFilters = (items, keyword, filters) => {
  const ignoreSizeFilter = _.isEmpty(filters?.size) || filters.size === FILTER_ALL;
  const ignorePackFilter = _.isEmpty(filters?.pack) || filters.pack === FILTER_ALL;

  if (!items) return [];
  if (items && _.isEmpty(keyword) && ignoreSizeFilter && ignorePackFilter) return items;

  const keywordFormatted = _.chain(keyword).lowerCase().trim().value();
  return _.filter(items, (item) => {
    const matchesKeyword = _.some(ITEM_SEARCH_INDICES, (index) =>
      _.chain(item[index]).lowerCase().includes(keywordFormatted).value()
    );
    const matchesSize = ignoreSizeFilter || filters.size === item.size;
    const matchesPack = ignorePackFilter || filters.pack === item.pack;

    return matchesKeyword && matchesSize && matchesPack;
  });
};

const ProductList = ({
  items,
  productsFormRef,
  onSave,
  batchStatus,
  searchKeyword,
  itemFilters,
  userRole,
  handleItemOfferCodeChange,
  onItemCommentsChange,
  handleItemRejection,
  activeOfferCodes,
  hideBatchInfo,
}) => {
  const [gridScrollHeight, setGridScrollHeight] = useState('70vh');

  const onResize = useCallback(
    (_0, height) => {
      setGridScrollHeight(height - 180);
    },
    [setGridScrollHeight]
  );

  if (_.isEmpty(items) || hideBatchInfo)
    return (
      <div className="grid-panel">
        <AntdTable
          columns={getColumns()}
          pagination={{ defaultPageSize: BATCHES_PAGE_SIZE }}
          scroll={{ x: 1500, y: 600 }}
          rowKey="supc"
        />
      </div>
    );

  return (
    <ReactResizeDetector handleHeight onResize={onResize}>
      <div className="grid-panel">
        <Formik
          innerRef={productsFormRef}
          id="productListForm"
          onSubmit={(values, { setSubmitting, resetForm }) => {
            onSave(values);
            setSubmitting(false);
            resetForm({ values });
          }}
          initialValues={items}
          validateOnChange={false}
          enableReinitialize
        >
          {({ values, dirty, setFieldValue }) => {
            handlePageLeave(dirty);
            return (
              <Table
                columns={getColumns(
                  setFieldValue,
                  batchStatus,
                  userRole,
                  handleItemOfferCodeChange,
                  onItemCommentsChange,
                  handleItemRejection,
                  activeOfferCodes
                )}
                pagination={{ defaultPageSize: BATCHES_PAGE_SIZE }}
                dataSource={filterItemsBasedOnKeywordAndFilters(values, searchKeyword, itemFilters)}
                scroll={{ x: '100%', y: gridScrollHeight }}
              />
            );
          }}
        </Formik>
      </div>
    </ReactResizeDetector>
  );
};

export default ProductList;
