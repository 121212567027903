const downloadFile = (fileName, data, fileType) => {
  // ufeff is used to support special characters regardless of the encoding used
  const file = new Blob([`\ufeff${data}`], { type: 'text/plain' });

  if (window.navigator.msSaveOrOpenBlob) {
    // Required for this to work in IE
    const fullName = `${fileName}.${fileType}`;
    window.navigator.msSaveBlob(file, fullName);
  } else {
    const element = document.createElement('a');
    element.href = URL.createObjectURL(file);
    element.download = `${fileName}.${fileType}`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }
};

const downloadCSV = (fileName, data) => {
  downloadFile(fileName, data, 'csv');
};

export default downloadCSV;
