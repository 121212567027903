import { RewardType } from 'enums';
import * as _ from 'lodash';

const generateBatchName = (rewardType, year, quarter) => {
  let batchName = `FY${year || ''}-`;
  const capitalizedRewardType = _.capitalize(rewardType);

  if (rewardType === RewardType.SEASONAL) batchName += `Q${quarter}-`;

  batchName += capitalizedRewardType;

  return batchName;
};

export default generateBatchName;
