import React from 'react';

const OfferCodesListPageContext = React.createContext({
  userRole: null,
  filters: {},
  offerCodes: [],
  totalOfferCodes: 0,
  offerCodesFetching: true,
  viewOfferCode: null,
  handleTypeFilterChange: null,
  handleStatusFilterChange: null,
  navigateToBatchesList: null,
  navigateToOfferCodesList: null,
  onChangePage: null,
  createOfferCode: null,
  isOfferCodeCreating: false,
  resetOfferCodeForm: null,
  isOfferCodeFormValid: null,
  typeSource: null,
  statusSource: null,
  onSearchOfferCodeId: null,
  offerCodeId: null,
});

export const OfferCodesListPageContextProvider = OfferCodesListPageContext.Provider;

export default OfferCodesListPageContext;
