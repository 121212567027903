import React from 'react';
import { useTranslation } from 'react-i18next';

function QuarterDetails() {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <span className="tooltip-title">Q1</span>
        <span> : </span>
        {t('rewards.quarterOne')}
      </div>
      <div>
        <span className="tooltip-title">Q2</span>
        <span> : </span>
        {t('rewards.quarterTwo')}
      </div>
      <div>
        <span className="tooltip-title">Q3</span>
        <span> : </span>
        {t('rewards.quarterThree')}
      </div>
      <div>
        <span className="tooltip-title">Q4</span>
        <span> : </span>
        {t('rewards.quarterFour')}
      </div>
    </div>
  );
}

export default QuarterDetails;
