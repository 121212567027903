import React from 'react';

const Comment = ({ index, title, content, dateTime }) => {
  return (
    <div id={`comment-${index}`} className="rewards-note" key={index}>
      <div id={`comment-${index}-title`} className="type">
        {title}
      </div>
      <div id={`comment-${index}-content`} className="note-content">
        {content}
      </div>
      <div id={`comment-${index}-date-time`} className="date-time">
        {dateTime}
      </div>
    </div>
  );
};

export default Comment;
