import React, { useCallback } from 'react';
import { Select } from 'antd';
import CommentsDropdown from 'components/CommentsDropdown';

import { BatchStatus, RewardType, UserRole } from 'enums';
import Badge from 'components/Badges/RewardBadge';
import { useTranslation } from 'react-i18next';
import useModalToggle from 'hooks/useModalToggle';
import { FILTER_ALL } from 'util/constants';
import DeleteModalHolder from 'components/DeleteModalHolder';
import SubmitModal from './SubmitModal';

const { Option } = Select;

export default function BatchHeader({
  userRole,
  showUploadModal,
  handleDownloadBatch,
  selectedBatch,
  handleChange,
  showBatchResults,
  keyword,
  handleChangeSize,
  date,
  navigateBack,
  saveBatch,
  saveAndSubmitBatch,
  onCommentChange,
  commentValue,
  itemFilters,
  activeOfferCodes,
  handleOfferCodeChange,
  batchOfferCodes,
  rejectedItemsCount,
  batchComments,
  onBatchCommentsChange,
  approveBatch,
  rejectBatch,
  hideBatchInfo,
  isDeleteBatchModalVisible,
  onToggleDeleteBatchModalVisible,
  onDelete,
}) {
  const { t } = useTranslation();
  const [submitModalVisible, toggleSubmitModalVisible] = useModalToggle();

  const submitModalOnOk = useCallback(() => {
    toggleSubmitModalVisible();
    saveAndSubmitBatch();
  }, [toggleSubmitModalVisible, saveAndSubmitBatch]);

  const isMasterVendor = userRole === UserRole.MASTER_VENDOR;
  const isSyscoAssociate = userRole === UserRole.SYSCO_ASSOCIATE;

  const isSubmittable = isMasterVendor && selectedBatch?.batchStatus === BatchStatus.OPEN;
  const isApprovable =
    isSyscoAssociate &&
    (selectedBatch?.batchStatus === BatchStatus.PENDING || selectedBatch?.batchStatus === BatchStatus.REVIEWED);
  const isSavable = isSubmittable || isApprovable;

  const isDeletable =
    isMasterVendor &&
    (selectedBatch?.batchStatus === BatchStatus.OPEN || selectedBatch?.batchStatus === BatchStatus.PENDING) &&
    selectedBatch?.batchId !== 'newBatch' &&
    selectedBatch?.batchId;

  return (
    <>
      <div id="batch-header-section">
        <div id="batch-header-panel" className="batch-view-header grid-filter-panel">
          <div id="batch-header-date-title" className="title">
            <div id="batch-header-date-wrapper" className="with-sublable-wrapper">
              <div id="batch-header-date" className="with-sublable-title-icon">
                {date}
              </div>
            </div>
          </div>
          {selectedBatch?.batchName && (
            <div id="batch-header-date-title" className="title">
              <div id="batch-header-date-wrapper" className="with-sublable-wrapper">
                <div id="batch-header-date" className="with-sublable-title-icon">
                  {selectedBatch?.batchName}
                </div>
              </div>
            </div>
          )}
          <div id="batch-header-info" className="batch-info">
            <div id="batch-header-main-info" className="main-info">
              <div id="batch-header-id-section" className="id-label">
                <span id="batch-header-id-label">{`${t('rewards.id')}:`}</span>
                {!hideBatchInfo && (
                  <div id="batch-header-id-value" className="batch-id">
                    {selectedBatch?.batchId}
                  </div>
                )}
                {selectedBatch?.rewardType === RewardType.LOYALTY && (
                  <Badge
                    id="batch-header-reward-tag-loyalty"
                    buttonClass="tag-loyalty edit-reward-tag"
                    icon="tag-loyalty-icon"
                    badge={t(`rewards.type.loyalty`)}
                  />
                )}
                {
                  // removed growth badge in MVP
                }
                {selectedBatch?.rewardType === RewardType.SEASONAL && (
                  <Badge
                    id="batch-header-reward-tag-seasonal"
                    buttonClass="tag-seasonal edit-reward-tag"
                    icon="tag-seasonal-icon"
                    badge={t(`rewards.type.seasonal`)}
                  />
                )}
              </div>
              {selectedBatch?.opcos === 'National' && (
                <div id="batch-header-opco-label" className="opco-label">
                  Multiple Operating Companies
                </div>
              )}
            </div>
            <div id="batch-header-notes" className="notes">
              <CommentsDropdown
                id="batch-header-comments"
                userRole={userRole}
                comments={batchComments}
                saveComment={onBatchCommentsChange}
              />
            </div>
          </div>
          <div id="batch-header-actions" className="batch-info">
            <div id="batch-header-actions-content" className="main-info">
              <div id="batch-header-actions-btn-group" className="id-label">
                {isApprovable && (
                  <>
                    <button
                      id="batch-merchandiser-approve"
                      type="button"
                      className="ant-btn btn green-action-btn ant-btn-primary"
                      onClick={approveBatch}
                    >
                      <i id="batch-merchandiser-approve-icon" className="fi flaticon-tick-1"></i>
                      <span id="batch-merchandiser-approve-label">{t(`rewards.approve`)}</span>
                    </button>
                  </>
                )}

                {isApprovable && (
                  <>
                    <div>
                      <button
                        id="batch-merchandiser-reject"
                        type="button"
                        className="ant-btn btn red-action-btn ant-btn-primary"
                        onClick={rejectBatch}
                      >
                        <i id="batch-merchandiser-reject-icon" className="fi flaticon-round-delete-button"></i>
                        <span id="batch-merchandiser-reject-label">{t(`rewards.reject`)}</span>
                      </button>
                    </div>
                  </>
                )}

                {isSubmittable && (
                  <>
                    <button
                      id="batch-merchandiser-submit-approval"
                      type="button"
                      className="ant-btn btn green-action-btn ant-btn-primary"
                      onClick={toggleSubmitModalVisible}
                    >
                      <i id="batch-submit-approval-icon" className="fi flaticon-tick-1"></i>
                      <span id="batch-submit-approval-label">{t(`rewards.batch.submitForApproval`)}</span>
                    </button>
                    <div className="dotted-connector"></div>
                  </>
                )}
                {isSavable && (
                  <>
                    <button id="batch-save" type="button" className="ant-btn btn ant-btn-primary" onClick={saveBatch}>
                      <i id="batch-save-icon" className="fi flaticon-save"></i>
                      <span id="batch-save-label">{t(`rewards.save`)}</span>
                    </button>
                  </>
                )}

                {isDeletable && (
                  <>
                    <React.Fragment>
                      <button
                        id="batch-save"
                        type="button"
                        className="ant-btn btn ant-btn-primary red-action-btn "
                        onClick={onToggleDeleteBatchModalVisible}
                      >
                        <i className="fi flaticon-delete"></i>
                        <span id="batch-save-label">{t(`rewards.delete`)}</span>
                      </button>
                      <DeleteModalHolder
                        visible={isDeleteBatchModalVisible}
                        onToggle={onToggleDeleteBatchModalVisible}
                        batchData={selectedBatch || null}
                        onDelete={onDelete}
                      />
                    </React.Fragment>
                  </>
                )}

                <button
                  id="batch-back-to-batch-list"
                  type="button"
                  className="ant-btn btn back-btn ant-btn-link"
                  onClick={navigateBack}
                >
                  <i id="batch-back-to-batch-list-icon" className="fi flaticon-arrow"></i>
                  <span id="batch-back-to-batch-list-label">{t(`rewards.batch.backToBatchList`)}</span>
                </button>
              </div>
              {isSyscoAssociate && rejectedItemsCount && rejectedItemsCount !== 0 ? (
                <div id="batch-header-reject-count-label" className="reject-count-label">
                  {`${t('rewards.item.rejectionPhraseOne')} ${rejectedItemsCount} ${t(
                    'rewards.item.rejectionPhraseTwo'
                  )}`}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="spacer"></div>
          {isSavable && isMasterVendor && (
            <button
              id="batch-header-upload"
              type="button"
              className="ant-btn btn ant-btn-primary"
              onClick={showUploadModal}
            >
              <i id="batch-header-upload-icon" className="fi flaticon-upload"></i>
              <span id="batch-header-upload-label">{t(`rewards.batch.upload`)}</span>
            </button>
          )}
          {isMasterVendor && (
            <button
              id="batch-header-download"
              type="button"
              className="ant-btn btn green-action-btn ant-btn-primary"
              onClick={handleDownloadBatch}
            >
              <i id="batch-header-download-icon" className="fi flaticon-download"></i>
              <span id="batch-header-download-label">{t(`rewards.batch.download`)}</span>
            </button>
          )}
          {isSyscoAssociate && (
            <div id="batch-header-offer-code-selector-section" className="edit-reward-multi-select">
              <span id="batch-header-offer-code-selector-label" className="lable">
                {t('rewards.batchItem.offerCode')}
              </span>
              <Select
                id="batch-header-offer-code-selector"
                style={{ flex: 1, maxHeight: '64px', overflow: 'auto' }}
                placeholder={t('rewards.pleaseSelect')}
                value={batchOfferCodes}
                onChange={handleOfferCodeChange}
                className="code-multi-select"
                disabled={!isApprovable}
              >
                {activeOfferCodes?.map((offerCodeId) => {
                  return (
                    <Option id={`batch-header-offer-code-${offerCodeId}`} key={offerCodeId}>
                      {offerCodeId}
                    </Option>
                  );
                })}
              </Select>
            </div>
          )}
        </div>
        <div id="batch-header-search-and-filter" className="grid-filter-panel">
          <input
            placeholder={t('rewards.keyword')}
            type="search"
            className="search"
            id="batches-search"
            onChange={showBatchResults}
            defaultValue={keyword}
          />
          <div className="spacer"></div>
          <div id="batch-header-filters" className="filters">
            <div id="batch-header-filter-group" className="variance">
              <div id="batch-header-filter-pack-wrap" className="variance-item">
                <label id="batch-header-filter-pack-label" htmlFor="pack">{`${t('rewards.item.pack')} `}</label>
                <Select
                  id="batch-header-filter-pack"
                  defaultValue={FILTER_ALL}
                  onChange={handleChange}
                  value={itemFilters.pack}
                >
                  <Option id="batch-header-filter-pack-all" key={FILTER_ALL} value={FILTER_ALL}>
                    {t('rewards.all')}
                  </Option>
                  {selectedBatch?.opcoItems
                    ?.filter((v, i, a) => a.findIndex((u) => u.pack === v.pack) === i)
                    .map((item, i) => (
                      <Option id={`batch-header-filter-pack-value-${i + 1}`} key={item.pack}>
                        {item.pack}
                      </Option>
                    ))}
                </Select>
              </div>
              <div id="batch-header-filter-item-size-wrap" className="variance-item">
                <label id="batch-header-filter-item-size-label" htmlFor="itemSize">
                  {`${t('rewards.item.size')} `}
                </label>
                <Select
                  id="batch-header-filter-item-size"
                  defaultValue={FILTER_ALL}
                  onChange={handleChangeSize}
                  value={itemFilters.size}
                >
                  <Option id="batch-header-filter-item-size-all" key={FILTER_ALL} value={FILTER_ALL}>
                    {t('rewards.all')}
                  </Option>
                  {selectedBatch?.opcoItems
                    ?.filter((v, i, a) => a.findIndex((u) => u.size === v.size) === i)
                    .map((item, i) => (
                      <Option id={`batch-header-filter-item-size-value-${i + 1}`} key={item.size}>
                        {item.size}
                      </Option>
                    ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SubmitModal
        id="batch-header-submit-modal"
        visible={submitModalVisible}
        onCancel={toggleSubmitModalVisible}
        onOk={submitModalOnOk}
        onCommentChange={onCommentChange}
        commentValue={commentValue}
      />
    </>
  );
}
