import React from 'react';
import { Formik, ErrorMessage } from 'formik';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Tooltip } from 'antd';
import { Input } from 'formik-antd';
import ButtonGroup from 'antd/lib/button/button-group';
import moment from 'moment';
import * as yup from 'yup';

import { BatchType, RewardType } from 'enums';
import i18n from '_i18n';
import QuarterDetails from './QuarterDetails';

const validRewardTypes = _.values(RewardType);
const validBatchTypes = _.values(BatchType);

const validationSchema = yup.object().shape({
  rewardType: yup.mixed().oneOf(validRewardTypes),
  batchType: yup.string().oneOf(validBatchTypes),
  opcos: yup.array(),
  batchNameFY: yup
    .number()
    .typeError(i18n.t('rewards.createBatch.error.fiscalYearNumber'))
    .min(parseInt(moment().format('YY'), 10), i18n.t('rewards.createBatch.error.FiscalYearMinError')),
  batchNameQ: yup.number().oneOf(_.range(1, 5, 1)),
});

const renderFormError = (error) => <p style={{ color: 'red' }}>{error}</p>;

function CreateBatchForm({ onCreateNewBatch, createBatchFormRef }) {
  const { t } = useTranslation();

  return (
    <Formik
      innerRef={createBatchFormRef}
      initialValues={{
        rewardType: RewardType.LOYALTY,
        batchType: BatchType.NATIONAL,
        opcos: [],
        batchNameFY: parseInt(moment().format('YY'), 10),
        batchNameQ: 1,
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        onCreateNewBatch(values);
        setSubmitting(false);
        resetForm({ values });
      }}
      validationSchema={validationSchema}
    >
      {({ values: { rewardType, batchNameQ }, setFieldValue }) => {
        return (
          <div id="batch-create-modal-contents" className="create-modal-contents">
            <div id="batch-create-modal-left-panel" className="left-panel">
              <p id="batch-create-modal-left-panel-heading" className="create-model-heading">
                {t('rewards.point.type')}
              </p>
              <div>
                <ButtonGroup id="batch-create-modal-reward-type-btn-group" className="">
                  {validRewardTypes.map((type) => (
                    <Button
                      id={`batch-create-modal-reward-type-${type}`}
                      onClick={() => setFieldValue('rewardType', type)}
                      className={rewardType === type ? 'tag-type-selected' : ''}
                    >
                      <span
                        id={`batch-create-modal-reward-type-${type.toLowerCase()}-icon`}
                        className={`tag-${type.toLowerCase()}-group-icon`}
                      />
                      <span id={`batch-create-modal-reward-type-${type.toLowerCase()}-btn-title`} className="btn-title">
                        {t(`rewards.type.${type.toLowerCase()}`)}
                      </span>
                    </Button>
                  ))}
                </ButtonGroup>
              </div>

              <p id="batch-create-modal-left-panel-heading" className="create-model-heading">
                {t('rewards.batch.batchFiscalYear')}
              </p>
              <Input
                type="number"
                name="batchNameFY"
                min={parseInt(moment().format('YY'), 10)}
                maxLength={2}
                className="rewards-fy-input"
              />
              <ErrorMessage name="batchNameFY">{(err) => renderFormError(err)}</ErrorMessage>

              {rewardType === RewardType.SEASONAL && (
                <>
                  <p id="batch-create-modal-left-panel-heading" className="create-model-heading">
                    {t('rewards.batch.batchQuarter')}

                    <Tooltip title={<QuarterDetails />}>
                      <Icon type="info-circle" theme="filled" className="info-icon" />
                    </Tooltip>
                  </p>

                  <div>
                    <ButtonGroup id="batch-create-modal-reward-type-btn-group">
                      {_.range(1, 5, 1).map((quarter) => (
                        <Button
                          key={quarter}
                          id={`batch-create-modal-reward-type-${quarter}`}
                          onClick={() => setFieldValue('batchNameQ', quarter)}
                          className={batchNameQ === quarter ? 'tag-type-selected' : ''}
                        >
                          <span id={`batch-create-modal-reward-type-${quarter}-btn-title`} className="btn-title">
                            {`Q${quarter}`}
                          </span>
                        </Button>
                      ))}
                    </ButtonGroup>
                  </div>
                </>
              )}

              <div className="rewards-desc-wrap">
                {validRewardTypes.map((type) => (
                  <div>
                    <span
                      id={`batch-create-modal-reward-type-${type.toLowerCase()}-tag`}
                      className={`tag-rewards btn tag-${type.toLowerCase()}`}
                    >
                      <span
                        id={`batch-create-modal-reward-type-${type.toLowerCase()}-tag-icon`}
                        className={`tag-${type.toLowerCase()}-icon`}
                      />
                      <span id={`batch-create-modal-reward-type-${type.toLowerCase()}-value`}>
                        {t(`rewards.type.${type.toLowerCase()}`)}
                      </span>
                    </span>
                    <p id={`batch-create-modal-reward-type-${type.toLowerCase()}-description`}>
                      {t(`rewards.${type.toLowerCase()}.description`)}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            {/* removed batch type and opco selector */}
          </div>
        );
      }}
    </Formik>
  );
}

export default CreateBatchForm;
