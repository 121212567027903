import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Select } from 'antd';

import * as rewardsActions from 'actions';
import Batches from 'pages/BatchesPage/BatchesPageContainer';
import AppLoader from 'components/AppLoader';
import PrivateRoute from 'components/PrivateRoute';
import EditBatchesPage from './pages/EditBatchesPage/EditBatchPageContainer';
import OfferCodesListPageContainer from 'pages/OfferCodesListPage/OfferCodesListPageContainer';
import OfferCodePageContainer from 'pages/OfferCodePage/OfferCodePageContainer';
import { APP_ROUTES } from 'util/constants';
import { DisplayedUserRole, UserRole } from 'enums';

import AppLogo from 'styles/images/app-logos/rewards-logo.svg';
import styles from 'styles/RewardsApp.scss';

const { Option } = Select;

const setAppLogo = () => {
  if (document.getElementById('app-logo')) {
    document.getElementById('app-logo').src = AppLogo;
  }
};

const setAppHeaderToAppMode = () => {
  if (document.getElementById('app-bar')) {
    document.getElementById('app-bar').className = 'app-bar app-mode';
  }
};

const COMMON_ROUTES_USER_ROLES = [UserRole.MASTER_VENDOR, UserRole.SYSCO_ASSOCIATE];

// Style use
function MainApp({ actions, userId, userRole, userFetching, setUserRole }) {
  setAppLogo();
  setAppHeaderToAppMode();

  useEffect(() => {
    actions.loadUserDetails(userId);
  }, [actions, userId]);

  useEffect(() => {
    if (userRole) setUserRole(DisplayedUserRole[userRole] || userRole);
  }, [setUserRole, userRole]);

  useEffect(() => {
    styles.use();
    return () => {
      styles.unuse();
    };
  }, []);

  return !userFetching ? (
    <div className="wrapper reward-wrapper">
      <Router>
        <PrivateRoute
          exact
          path={APP_ROUTES.BATCHES_LIST}
          component={Batches}
          roles={COMMON_ROUTES_USER_ROLES}
          currentUserRole={userRole}
        />
        <PrivateRoute
          path={`${APP_ROUTES.BATCH_VIEW}/:id`}
          component={EditBatchesPage}
          roles={COMMON_ROUTES_USER_ROLES}
          currentUserRole={userRole}
        />
        <PrivateRoute
          path={APP_ROUTES.OFFER_CODES_LIST}
          component={OfferCodesListPageContainer}
          roles={[UserRole.SYSCO_ASSOCIATE]}
          currentUserRole={userRole}
        />
        <PrivateRoute
          path={`${APP_ROUTES.OFFER_CODE_VIEW}/:id`}
          component={OfferCodePageContainer}
          roles={[UserRole.SYSCO_ASSOCIATE]}
          currentUserRole={userRole}
        />
      </Router>
    </div>
  ) : (
    <AppLoader show />
  );
}

function mapStateToProps(state) {
  return {
    user: state.user.data?.userRole,
    userFetching: state.user.fetching,
    batchIds: state.user.data.batchIds,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(rewardsActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainApp);
