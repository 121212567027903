import React from 'react';
import { Modal, Button, Icon, Input, Form } from 'antd';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

export default function SubmitModal({ visible, onOk, onCancel, commentValue, onCommentChange }) {
  const { t } = useTranslation();

  return (
    <Modal
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <div id="submit-modal-button-group" className="modal-btn-row" key={1}>
          <Button id="submit-modal-cancel-button" key="No" onClick={onCancel} className="btn">
            {t('rewards.modal.cancel')}
          </Button>
          <Button id="submit-modal-submit-button" key="Yes" onClick={onOk} className="btn ant-btn-primary">
            {t('rewards.modal.submit')}
          </Button>
        </div>,
      ]}
    >
      <div id="submit-modal-confirm-label" className="modal-confirm-label">
        <Icon id="submit-modal-confirm-icon" className="icon" type="question-circle" theme="filled" />
        <div id="submit-modal-display-text" className="sub">
          {`${t('rewards.navigationOption.submit')} ${t('rewards.DangerModal.confirmSubmitQuestion')}`}
        </div>
      </div>
      <Form.Item>
        <TextArea
          id="submit-modal-text-area"
          rows={4}
          onChange={onCommentChange}
          value={commentValue}
          placeholder={t('rewards.sumbitModal.placeholder')}
        />
      </Form.Item>
    </Modal>
  );
}
