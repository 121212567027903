import React, { useContext } from 'react';

import AppLoader from 'components/AppLoader';
import OfferCodeViewPageContext from 'context/OfferCodeViewContext';
import OfferCodeForm from 'components/OfferCodeForm';
import OfferCodeHeader from './OfferCodeHeader';
import OfferCodeItemList from './OfferCodeItemList';

function OfferCodePageComponent() {
  const { isDataLoading } = useContext(OfferCodeViewPageContext);

  return (
    <>
      <OfferCodeHeader />
      <div id="offer-code-view-section" className="grid-panel grid-panel-offer-code-edit">
        <div id="offer-code-edit-form-section" className="left-panel-offercode rewards-divider-ver">
          <OfferCodeForm />
        </div>
        <OfferCodeItemList />
      </div>
      {isDataLoading && <AppLoader />}
    </>
  );
}

export default OfferCodePageComponent;
