import React from 'react';
import { Modal, Button, Icon } from 'antd';
import { useTranslation } from 'react-i18next';

export default function DangerModal({ visible, onOk, onCancel, batchData }) {
  const { t } = useTranslation();

  return (
    <Modal
      visible={visible}
      onOk={() => {
        onOk(batchData);
      }}
      onCancel={onCancel}
      footer={[
        <div className="modal-btn-row" key="row-1">
          <Button id="danger-modal-no-btn" key="No" onClick={onCancel} className="btn">
            {t('rewards.cancel')}
          </Button>
          <Button
            id="danger-modal-yes-btn"
            key="Yes"
            onClick={() => {
              onOk(batchData);
            }}
            className="btn red-action-btn ant-btn-primary"
          >
            {t('rewards.delete')}
          </Button>
        </div>,
      ]}
    >
      <div className="modal-confirm-label">
        <Icon className="icon" type="question-circle" theme="filled" />
        <div className="sub">
          {t('rewards.DangerModal.confirmDeleteQuestion', {
            batchId: batchData?.batchId,
          })}
        </div>
      </div>
    </Modal>
  );
}
