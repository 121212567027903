export const DOWNLOAD_DATE_PATTERN = 'DD_MMM_YYYY_hh_mm_ss';
export const BATCHES_PAGE_SIZE = 40;
export const DATE_PATTERN = 'YYYY-MM-DD';
export const DATE_PATTERN_DISPLAY = 'DD MMMM YYYY';
export const COMMENT_TIME_PATTERN = 'DD MMMM YYYY hh:mm:ss';

export const BATCH_UPDATE_EXPORT_CSV_FILE_NAME = 'Rewards_Batch';
export const MIN_REWARD_POINTS = 0;
export const MAX_REWARD_POINTS = 9999.99;

export const ITEM_SEARCH_INDICES = ['description', 'brand', 'pack', 'size', 'supc', 'mfgProductNumber', 'gtin'];
export const BATCH_DOWNLOAD_COLUMNS = [
  'batchId',
  'supc',
  'brand',
  'description',
  'size',
  'gtin',
  'pack',
  'mfgProductNumber',
  'loyaltyPoints',
  // 'growthPoints',
  'seasonalPoints',
  'newRewardPoints',
];
export const FILTER_ALL = 'All';

export const NEW_REWARD_POINTS = 'newRewardPoints';
export const OFFER_CODE_IDS = 'offerCodeIds';
export const REJECT_ITEM = 'rejectItem';
export const YES = 'Y';
export const NO = 'N';
export const NEW_BATCH = 'newBatch';
export const NATIONAL_OPCO = '000';

export const BATCH_UPLOAD_ERROR = 'error';

export const NOT_APPLICABLE = 'N/A';

export const APP_ROUTES = {
  BATCHES_LIST: '/suite/rewards',
  BATCH_VIEW: '/suite/rewards/batch', // /suite/rewards/editBatch/:id
  OFFER_CODES_LIST: '/suite/rewards/offerCodes',
  OFFER_CODE_VIEW: '/suite/rewards/offerCode', // /suite/rewards/offerCode/:id
};

export const OFFER_CODE_MAX_LENGTH = 15;

export const ISO_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

export const BAD_REQUEST_STATUS = 400;
export const NOT_FOUND_STATUS = 404;
export const USER_NOT_AUTHORIZED_STATUS = 401;

export const BATCH_NAME_REGEX = 'FY[0-9][0-9]-(Loyalty|Q[1-4]-Seasonal)';
