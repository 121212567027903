import React, { useContext } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { FILTER_ALL } from 'util/constants';
import OfferCodesListPageContext from 'context/OfferCodesListContext';

const { Option } = Select;

function OfferCodeFilters() {
  const {
    handleTypeFilterChange,
    typeSource,
    statusSource,
    handleStatusFilterChange,
    offerCodesFetching,
    offerCodeFilters: { status, rewardType },
  } = useContext(OfferCodesListPageContext);
  const { t } = useTranslation();

  return (
    <div id="offer-code-list-filters" className="filters">
      <div id="offer-code-list-reward-type-filter" className="filter">
        <label id="offer-code-list-reward-type-filter-label" htmlFor="rewardTypeFilter">
          {t(`rewards.type`)}
        </label>
        <Select
          id="offer-code-list-reward-type-filter-selector"
          value={rewardType}
          onChange={handleTypeFilterChange}
          disabled={offerCodesFetching}
          dropdownMatchSelectWidth={false}
        >
          <Option id="offer-code-list-reward-type-filter-all" key={FILTER_ALL} value={FILTER_ALL}>
            {t('rewards.all')}
          </Option>
          {typeSource()}
        </Select>
      </div>
      <div id="offer-code-list-status-filter" className="filter">
        <label id="offer-code-list-status-filter-label" htmlFor="statusFilter">
          {t(`rewards.status`)}
        </label>
        <Select
          id="offer-code-list-status-filter-selector"
          value={status}
          onChange={handleStatusFilterChange}
          disabled={offerCodesFetching}
          dropdownMatchSelectWidth={false}
        >
          <Option id="offer-code-list-status-filter-all" key={FILTER_ALL} value={FILTER_ALL}>
            {t('rewards.all')}
          </Option>
          {statusSource()}
        </Select>
      </div>
    </div>
  );
}

export default OfferCodeFilters;
