const initialState = {
  user: {
    fetching: true,
    data: {
      email: null,
      userId: null,
      name: null,
      userRole: null,
      vendorList: [],
    },
    error: null,
  },
  batches: {
    fetching: true,
    data: null,
    error: null,
  },
  batch: {
    fetching: false,
    data: {
      batchType: null,
      effectiveDate: null,
      batchId: null,
      opcoItems: [],
      opcos: [],
      priceType: null,
      status: null,
      vendorName: null,
      comments: null,
    },
    error: null,
  },
  loadRewardItems: {
    fetching: false,
    error: null,
  },
  createBatch: {
    fetching: false,
    data: {
      batchType: null,
      batchId: null,
      opcoItems: [],
      opcos: [],
      status: null,
      vendorName: null,
      comments: null,
    },
    error: null,
  },
  updateBatch: {
    fetching: false,
    data: [],
    error: null,
  },
  deleteBatch: {
    fetching: false,
    data: [],
    error: null,
  },
  updateOfferCode: {
    fetching: false,
    data: [],
    error: null,
  },
  saveAndUpdateBatchStatus: {
    fetching: false,
    data: [],
    error: null,
  },
  filters: {
    fetching: true,
    data: {
      vendorIds: null,
      sort: null,
      opco: 'All',
      batchStatus: 'All',
      startDate: null,
      batchId: null,
      endDate: null,
      page: 1,
      rewardType: 'All',
    },
    error: null,
  },
  offerCodeFilters: {
    fetching: true,
    data: {
      sort: null,
      opco: 'All',
      status: 'All',
      startDate: null,
      offerCodeId: null,
      endDate: null,
      page: 1,
      rewardType: 'All',
    },
    error: null,
  },
  offerCodes: {
    fetching: true,
    data: [],
    error: null,
  },
  offerCode: {
    fetching: true,
    data: {
      offerCodeId: null,
      startDate: 0,
      endDate: 0,
      associateName: null,
      comments: null,
      rewardType: null,
      status: null,
      items: [],
    },
    error: null,
  },
  createOfferCode: {
    fetching: false,
    data: {
      offerCodeId: null,
      startDate: 0,
      endDate: 0,
      associateName: null,
      comments: null,
      rewardType: null,
      status: null,
    },
    error: null,
  },
};
export default initialState;
