import { createRequestTypes } from 'reduxHelpers';

const USER = createRequestTypes('USER');
const BATCHES = createRequestTypes('BATCHES');
const BATCH = createRequestTypes('BATCH');
const ITEMS = createRequestTypes('ITEMS');
const CREATE_BATCH = createRequestTypes('CREATE_BATCH');
const UPDATE_BATCH = createRequestTypes('UPDATE_BATCH');
const DELETE_BATCH = createRequestTypes('DELETE_BATCH');
const SAVE_UPDATE_BATCH_STATUS = createRequestTypes('UPDATE_BATCH_STATUS');
const UPDATE_FILTERS = createRequestTypes('UPDATE_FILTERS');
const OFFER_CODES = createRequestTypes('OFFER_CODES');
const OFFER_CODE = createRequestTypes('OFFER_CODE');
const CREATE_OFFER_CODE = createRequestTypes('CREATE_OFFER_CODE');
const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
const UPDATE_OFFER_CODE_FILTERS = createRequestTypes('UPDATE_OFFER_CODE_FILTERS');
const UPDATE_OFFER_CODE = createRequestTypes('UPDATE_OFFER_CODE');
const FETCH_ALL_OFFER_CODES = createRequestTypes('FETCH_ALL_OFFER_CODES');

export {
  USER,
  BATCH,
  BATCHES,
  ITEMS,
  CREATE_BATCH,
  UPDATE_FILTERS,
  UPDATE_BATCH,
  SAVE_UPDATE_BATCH_STATUS,
  OFFER_CODES,
  OFFER_CODE,
  CREATE_OFFER_CODE,
  SHOW_NOTIFICATION,
  UPDATE_OFFER_CODE_FILTERS,
  UPDATE_OFFER_CODE,
  FETCH_ALL_OFFER_CODES,
  DELETE_BATCH,
};
