import React, { useContext } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import RewardsTabs from 'components/RewardsTabs';
import AppLoader from 'components/AppLoader';
import OfferCodesListPageContext from 'context/OfferCodesListContext';
import useModalToggle from 'hooks/useModalToggle';
import OfferCodeFilters from './OfferCodeFilters';
import OfferCodesList from './OfferCodesList';
import CreateOfferCodeModal from './CreateOfferCodeModal';

function OfferCodesListPageComponent() {
  const { t } = useTranslation();

  const { userRole, navigateToBatchesList, isOfferCodeCreating, onSearchOfferCodeId, offerCodeId, offerCodesFetching } =
    useContext(OfferCodesListPageContext);
  const [isCreateOfferCodeModalVisible, toggleCreateOfferCodeModal] = useModalToggle();

  return (
    <>
      <>
        <div id="offer-code-filter-and-search" className="grid-filter-panel">
          <RewardsTabs userRole={userRole} navigateToBatchesList={navigateToBatchesList} />
          <div id="offer-code-title" className="title reward">
            {t(`rewards.offerCode`)}
          </div>
          <div id="offer-code-filter-and-search-wrap" className="filter batch-search-wrap">
            <span id="offer-code-search-input-group" className="ant-input-wrapper ant-input-group">
              <span
                id="offer-code-search-section"
                className="ant-input-search ant-input-search-enter-button ant-input-affix-wrapper"
              >
                <div id="offer-code-search-wrap" className="filter batch-search-wrap">
                  <input
                    placeholder={t('rewards.offerCode')}
                    type="search"
                    className="search"
                    id="offer-codes-search"
                    onKeyDown={onSearchOfferCodeId}
                    defaultValue={offerCodeId}
                    disabled={offerCodesFetching}
                  />
                  {/* <Button type="link" className="advanced-search-link"></Button> */}
                </div>
              </span>
            </span>
          </div>
          <Button
            id="create-new-btn"
            type="button"
            className="ant-btn btn create-new-btn ant-btn-primary"
            onClick={toggleCreateOfferCodeModal}
          >
            <i id="offer-code-create-icon" className="fi flaticon-add-button-inside-black-circle"></i>
            <span id="offer-code-create-label">{t(`rewards.offerCode.addOfferCode`)}</span>
          </Button>
          <div className="spacer"></div>
          <OfferCodeFilters />
        </div>
        <OfferCodesList />
      </>
      <CreateOfferCodeModal visible={isCreateOfferCodeModalVisible} toggleModal={toggleCreateOfferCodeModal} />
      {isOfferCodeCreating && <AppLoader />}
    </>
  );
}

export default OfferCodesListPageComponent;
