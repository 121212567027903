import React, { useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';

import { NotificationTypes } from 'enums';
import * as rewardsActions from 'actions';
import { OfferCodeViewPageContextProvider } from 'context/OfferCodeViewContext';
import { APP_ROUTES } from 'util/constants';
import { OfferCodeFormContextProvider } from 'context/OfferCodeFormContext';
import OfferCodePageComponent from './components/OfferCodePageComponent';

const buildOfferCodeFromDataFromForm = (values) => {
  return values;
};

function OfferCodePageContainer({ actions, offerCode, offerCodeFetching, updatingOfferCode }) {
  const { id: selectedOfferCodeId } = useParams();

  const history = useHistory();

  const { t } = useTranslation();

  useEffect(() => actions.loadOfferCodeDetails(selectedOfferCodeId), [selectedOfferCodeId]);

  const offerCodeFormRef = useRef();

  const onUpdateOfferCode = useCallback(
    () => actions.updateOfferCode(buildOfferCodeFromDataFromForm(offerCodeFormRef.current?.values), history),
    [offerCodeFormRef, actions, history]
  );

  const updateOfferCode = useCallback(
    () =>
      offerCodeFormRef.current?.dirty
        ? offerCodeFormRef.current?.submitForm()
        : actions.showNotification(
            t('rewards.offerCode.info.message'),
            t('rewards.offerCode.notChanged.description'),
            NotificationTypes.INFO
          ),
    [offerCodeFormRef]
  );

  const updateOfferCodeContextValue = {
    offerCodeFormRef,
    onSave: onUpdateOfferCode,
    offerCode,
    isEditing: true,
  };

  const navigateToOfferCodeList = useCallback(() => {
    history.push(APP_ROUTES.OFFER_CODES_LIST);
  }, [history]);

  const pageContextValue = {
    offerCode,
    isDataLoading: offerCodeFetching || updatingOfferCode,
    navigateToOfferCodeList,
    updateOfferCode,
  };

  return (
    <OfferCodeViewPageContextProvider value={pageContextValue}>
      <OfferCodeFormContextProvider value={updateOfferCodeContextValue}>
        <OfferCodePageComponent />
      </OfferCodeFormContextProvider>
    </OfferCodeViewPageContextProvider>
  );
}
function mapStateToProps(state) {
  return {
    offerCode: state.offerCode.data,
    offerCodeFetching: state.offerCode.fetching,
    updatingOfferCode: state.updateOfferCode.fetching,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(rewardsActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferCodePageContainer);
