import { Modal, Upload, Icon } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Dragger } = Upload;

function UploadModel({
  closeCreateModal,
  visible,
  onChangeFile,
  batchId,
  onRemoveFile,
  onFileAdded,
  onUpload,
  disableButton,
}) {
  const { t } = useTranslation();
  return (
    <Modal
      title=""
      centered
      visible={visible}
      onCancel={closeCreateModal}
      destroyOnClose
      width={700}
      footer={null}
      className="create-modal"
    >
      <div id="batch-upload-modal" className="">
        <div id="batch-upload-modal-header" className="ant-modal-header">
          <div id="batch-upload-modal-header-title" className="ant-modal-title">
            {t('rewards.modal.upload.title')}
            {batchId ? ` #${batchId}` : ''}
          </div>
        </div>
        <div id="batch-upload-modal-body" className="ant-modal-create-body">
          <div className="create-modal-contents ">
            <div className="upload-modal-container">
              <div className="upload-select-file-container">
                <Dragger
                  id="batch-upload-modal-dragger"
                  accept=".csv"
                  showUploadList
                  onChange={onChangeFile}
                  onRemove={onRemoveFile}
                  beforeUpload={(file) => {
                    onFileAdded(file);
                    // prevent upload
                    return false;
                  }}
                >
                  <p id="batch-upload-modal-dragger-icon" className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p id="batch-upload-modal-dragger-text" className="ant-upload-text">
                    {t('rewards.modal.upload.draggerText')}
                  </p>
                  <p id="batch-upload-modal-dragger-hint" className="ant-upload-hint">
                    {t('rewards.modal.upload.warnText')}
                  </p>
                </Dragger>
              </div>
            </div>
          </div>
          <div id="batch-upload-modal-footer" className="ant-modal-footer">
            <div>
              <button
                id="batch-upload-modal-upload-button"
                type="button"
                className="ant-btn btn white-action-btn no-shadow no-flex"
                disabled={disableButton}
                onClick={onUpload}
              >
                <span id="batch-upload-modal-upload-label">{t('rewards.batch.upload')}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default UploadModel;
