import * as _ from 'lodash';
import { BATCH_DOWNLOAD_COLUMNS, NEW_BATCH } from './constants';

const getColumnTitleValue = (element, column) => {
  if (column.key === 'batchId' && element[column.key] === NEW_BATCH) {
    return '';
  }

  if (column.key === 'supc') {
    return `'${element[column.key]}'`;
  }

  return element[column.key];
};

/**
 * item row map for frontend downloading
 * @param  {Object} element
 * @param  {Object} columns
 */
const dataRowMap = (element, columns) => {
  return columns.reduce(
    (rowObject, column) => ({
      ...rowObject,
      [column.title]: getColumnTitleValue(element, column),
    }),
    {}
  );
};

/**
 * map items for download CSV
 * @param {Array} itemArray
 * @param {Array} columns
 */
const downloadItemMapper = (itemArray, columns) => {
  return _.chain(itemArray)
    .map((element) => dataRowMap(element, columns))
    .sortBy((item) => item.supc)
    .value();
};

export const getBatchTableColumns = (t, translationPrefix = 'rewards.batchItem') => {
  return BATCH_DOWNLOAD_COLUMNS.map((columnKey) => ({ key: columnKey, title: t(`${translationPrefix}.${columnKey}`) }));
};

export default downloadItemMapper;
