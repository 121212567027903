import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import Papa from 'papaparse';
import _ from 'lodash';
import * as yup from 'yup';

import i18n from '_i18n';
import * as batchesActions from 'actions';
import EditBatchPageComponent from 'pages/EditBatchesPage/components/EditBatchPageComponent';
import useModalToggle from 'hooks/useModalToggle';
import {
  BATCH_UPDATE_EXPORT_CSV_FILE_NAME,
  BATCH_UPLOAD_ERROR,
  DATE_PATTERN,
  DATE_PATTERN_DISPLAY,
  FILTER_ALL,
  NEW_BATCH,
} from 'util/constants';
import { BatchStatus, BatchType, NotificationTypes, RewardType, UserRole } from 'enums';
import downloadItemMapper, { getBatchTableColumns } from 'util/downloadItemMapper';
import { useTranslation } from 'react-i18next';
import generateCSVFilename from 'util/generateCSVFileName';
import downloadCSV from 'util/downloadCSV';
import uploadItemMapper from 'util/uploadItemMapper';
import openNotification from 'components/openNotification';
import handlePageLeave from 'util/handlePageLeave';

const buildBatchFromFormValues = (selectedBatch, formRef, batchComments, batchOfferCodes) =>
  selectedBatch && formRef?.current
    ? {
        ...selectedBatch,
        opcoItems: [...formRef.current.values],
        comments: batchComments,
        offerCodeIds: batchOfferCodes || selectedBatch?.offerCodeIds,
      }
    : {};

const getBatchNameURLComponentValidators = (rewardType) => {
  const batchFYValidation = yup
    .number()
    .required(i18n.t('rewards.createBatch.error.fiscalYearNumber'))
    .typeError(i18n.t('rewards.createBatch.error.fiscalYearNumber'))
    .min(parseInt(moment().format('YY'), 10), i18n.t('rewards.createBatch.error.FiscalYearMinError'));

  const batchNameQuarterValidation =
    rewardType === RewardType.SEASONAL
      ? yup
          .number()
          .required(i18n.t('rewards.createBatch.error.quarterError'))
          .typeError(i18n.t('rewards.createBatch.error.quarterError'))
          .oneOf(_.range(1, 5, 1), i18n.t('rewards.createBatch.error.quarterError'))
      : yup.string().typeError(i18n.t('rewards.createBatch.error.quarterError')).optional();

  return [batchFYValidation, batchNameQuarterValidation];
};

function EditBatchPage({
  actions,
  selectedBatch,
  batchFetching,
  batchUpdating,
  statusUpdating,
  batchCreating,
  itemsLoading,
  userRole,
  user,
  vendorId,
  offerCodeList,
}) {
  const productsFormRef = useRef();
  const history = useHistory();
  const { search } = useLocation();
  const { keyword, rewardType, batchType, batchFY, batchQuarter } = queryString.parse(search);
  const [createUploadModalVisible, onToggleUploadModalVisible] = useModalToggle();
  const { id: batchId } = useParams();
  const { t } = useTranslation();
  const [batchSubmitComment, setBatchSubmitComment] = useState(null);
  const [itemFilters, setItemFilters] = useState({ size: FILTER_ALL, pack: FILTER_ALL });
  const [uploadedData, setUploadData] = useState(null);
  const [disableButton, setDisableButton] = useState(true);
  const [batchOfferCodes, setBatchOfferCodes] = useState([]);
  const [rejectedItemsCount, setRejectedItemsCount] = useState(0);
  const [batchComments, setBatchComments] = useState([]);
  const [activeOfferCodes, setActiveOfferCodes] = useState([]);
  const [isDeleteBatchModalVisible, onToggleDeleteBatchModalVisible] = useModalToggle();

  const onDelete = useCallback(
    (batchData) => {
      actions.deleteBatch(batchData?.batchId, true, history);
      onToggleDeleteBatchModalVisible();
    },
    [actions, history, onToggleDeleteBatchModalVisible]
  );
  const updateRejectedItemCount = useCallback(() => {
    const itemList = productsFormRef?.current?.values?.map((item) => ({ ...item, batchId }));

    if (itemList) {
      const rejectedCount = itemList?.filter((item) => item?.rejectItem?.rejected === true)?.length || 0;
      setRejectedItemsCount(rejectedCount || 0);
    }
  }, [productsFormRef, setRejectedItemsCount, batchId]);

  useEffect(() => {
    if (userRole === UserRole.SYSCO_ASSOCIATE) actions.loadAllOfferCodes();
  }, [actions, userRole]);

  useEffect(() => {
    if (!batchFetching) {
      setBatchOfferCodes(selectedBatch?.offerCodeIds || []);
      updateRejectedItemCount();
      setBatchComments(selectedBatch?.comments || []);
    }

    if (selectedBatch?.opcoItems) {
      const rejectedCount =
        selectedBatch?.opcoItems?.filter((item) => item?.rejectItem?.rejected === true)?.length || 0;
      setRejectedItemsCount(rejectedCount || 0);
    }
  }, [
    selectedBatch,
    updateRejectedItemCount,
    setRejectedItemsCount,
    batchFetching,
    setBatchComments,
    setBatchOfferCodes,
  ]);

  useEffect(() => {
    const eligbleOfferCodes =
      offerCodeList?.filter((offerCode) => offerCode.rewardType === selectedBatch.rewardType) || [];
    setActiveOfferCodes(eligbleOfferCodes?.map((offerCode) => offerCode?.offerCodeId));
  }, [offerCodeList, selectedBatch.rewardType]);

  const onBatchCommentsChange = useCallback(
    (text) => {
      if (!_.isEmpty(text)) {
        setBatchComments([
          {
            authorName: user.name,
            authorId: user.userId,
            text,
            postedTime: _.now(),
          },
          ...batchComments,
        ]);
      }
    },
    [setBatchComments, batchComments, user.name, user.userId]
  );

  const onItemCommentsChange = (item, comment, setFieldValue) => {
    if (comment) {
      const comments = _.cloneDeep(item?.comments);
      comments.push({
        authorName: user.name,
        authorId: user.userId,
        text: comment,
        postedTime: _.now(),
      });

      setFieldValue(`${item.key}.comments`, comments);
    }
  };

  const handleItemRejection = async (item, isRejected, setFieldValue) => {
    await setFieldValue(
      `${item.key}.rejectItem`,
      isRejected
        ? {
            rejectorId: user.userId,
            rejected: true,
            rejectedTime: _.now(),
          }
        : null
    );

    updateRejectedItemCount();
  };

  useEffect(() => {
    if (vendorId) {
      if (batchId === NEW_BATCH && userRole === UserRole.MASTER_VENDOR) {
        try {
          const [batchFYValidation, batchNameQuarterValidation] = getBatchNameURLComponentValidators(rewardType);
          batchFYValidation.validateSync(batchFY);

          try {
            batchNameQuarterValidation.validateSync(batchQuarter);

            actions.loadRewardItems(vendorId, rewardType, batchFY, batchQuarter);
          } catch (error) {
            actions.showNotification(t('rewards.createBatch.error.message'), error.message, NotificationTypes.ERROR);
          }
        } catch (error) {
          actions.showNotification(t('rewards.createBatch.error.message'), error.message, NotificationTypes.ERROR);
        }
      } else if (batchId === NEW_BATCH) {
        actions.showNotification(
          i18n.t('rewards.user.error.message'),
          i18n.t('rewards.user.error.notAuthorized.description'),
          NotificationTypes.ERROR
        );
      } else if (batchId) {
        actions.loadBatchDetails(batchId);
      } else {
        // eslint-disable-next-line no-console
        console.error('Batch ID Not Found');
      }
    } else {
      actions.showNotification(
        i18n.t('rewards.user.error.message'),
        i18n.t('rewards.vendor.error.noVendorId.description'),
        NotificationTypes.ERROR
      );
    }

    return () => {
      setRejectedItemsCount(0);
      setBatchOfferCodes([]);
      setBatchComments([]);
      handlePageLeave(false);
    };
  }, [
    actions,
    batchId,
    rewardType,
    vendorId,
    setRejectedItemsCount,
    setBatchOfferCodes,
    setBatchComments,
    userRole,
    batchFY,
    batchQuarter,
    t,
  ]);

  const showBatchResults = (e) => {
    const searchValue = e.target.value;
    if (searchValue) {
      history.push({
        pathname: `/suite/rewards/batch/${batchId}`,
        state: { params: `${batchId}` },
        search: queryString.stringify({ rewardType, batchType, batchFY, batchQuarter, keyword: searchValue }),
      });
    } else {
      history.push({
        pathname: `/suite/rewards/batch/${batchId}`,
        state: { params: `${batchId}` },
        search: queryString.stringify({ rewardType, batchType, batchFY, batchQuarter }),
      });
    }
  };

  const handleChange = (key) => {
    setItemFilters({ ...itemFilters, pack: key });
  };

  const handleChangeSize = (key) => {
    setItemFilters({ ...itemFilters, size: key });
  };

  const populateTimefromEpoch = (rewardBatch) => {
    const updatedBatch = { ...rewardBatch };
    updatedBatch.createdDate = moment(rewardBatch.createdDate).format(DATE_PATTERN_DISPLAY);

    return updatedBatch;
  };

  const getDate = () => {
    if (selectedBatch) {
      const batch = populateTimefromEpoch(selectedBatch);
      return batch.createdDate;
    }
    return '';
  };

  const navigateBack = () => {
    history.push('/suite/rewards');
  };

  const handleDownloadBatch = () => {
    const itemList = productsFormRef?.current?.values?.map((item) => ({ ...item, batchId }));
    if (_.isEmpty(itemList))
      openNotification({
        message: t('rewards.batch.error.noItems.message'),
        description: t('rewards.batch.error.noItems.description'),
        className: 'error',
        isClosable: true,
      });
    else {
      const data = downloadItemMapper(itemList, getBatchTableColumns(t));
      const formattedDate = moment().format(DATE_PATTERN);
      const secondaryFilename = `${BATCH_UPDATE_EXPORT_CSV_FILE_NAME}_${generateCSVFilename(
        selectedBatch
      )}_${formattedDate}`;
      downloadCSV(selectedBatch?.batchName || secondaryFilename, Papa.unparse(data, { header: true }));
    }
  };

  const onBatchSave = useCallback(() => {
    const updatedBatch = buildBatchFromFormValues(selectedBatch, productsFormRef, batchComments, batchOfferCodes);
    if (batchId === NEW_BATCH) {
      actions.createBatch(updatedBatch, history);
    } else {
      actions.updateBatch(batchId, updatedBatch, history);
    }
  }, [batchId, selectedBatch, batchComments, productsFormRef, actions, batchOfferCodes, history]);

  const saveBatch = useCallback(() => {
    productsFormRef.current.submitForm();
  }, [productsFormRef]);

  const saveAndSubmitBatch = useCallback(() => {
    const newBatchList = [];
    newBatchList.push(buildBatchFromFormValues(selectedBatch, productsFormRef, batchComments, batchOfferCodes));
    actions.saveAndUpdateBatchStatus({
      batchId,
      comments: batchSubmitComment ? [batchSubmitComment] : [],
      batchStatus: BatchStatus.PENDING,
      batchDataList: newBatchList,
      history,
      isMultipleBatchesSelected: false,
      isBatchCreating: batchId === NEW_BATCH,
    });
  }, [batchSubmitComment, batchComments, batchId, actions, selectedBatch, productsFormRef, batchOfferCodes, history]);

  const approveBatch = useCallback(() => {
    const newBatchList = [];
    newBatchList.push(buildBatchFromFormValues(selectedBatch, productsFormRef, batchComments, batchOfferCodes));
    actions.saveAndUpdateBatchStatus({
      batchId,
      comments: [],
      batchStatus: BatchStatus.APPROVED,
      batchDataList: newBatchList,
      history,
      isMultipleBatchesSelected: false,
      isBatchCreating: false,
    });
  }, [actions, batchId, selectedBatch, batchComments, productsFormRef, batchOfferCodes, history]);

  const rejectBatch = useCallback(() => {
    const newBatchList = [];
    newBatchList.push(buildBatchFromFormValues(selectedBatch, productsFormRef, batchComments, batchOfferCodes));
    actions.saveAndUpdateBatchStatus({
      batchId,
      comments: [],
      batchStatus: BatchStatus.REJECTED,
      batchDataList: newBatchList,
      history,
      isMultipleBatchesSelected: false,
      isBatchCreating: false,
    });
  }, [actions, batchId, selectedBatch, batchComments, productsFormRef, batchOfferCodes, history]);

  const onCommentChange = useCallback(
    ({ target: { value: comment } = {} } = {}) => {
      setBatchSubmitComment(comment);
    },
    [setBatchSubmitComment]
  );

  const onReadCSV = (csvData) => {
    const values = uploadItemMapper(productsFormRef.current.values, csvData, BatchType.NATIONAL, batchId, user);
    productsFormRef.current.resetForm({ values });
    onToggleUploadModalVisible();
  };

  const onChangeFile = (info) => {
    const { status } = info.file;
    if (status === BATCH_UPLOAD_ERROR) {
      openNotification({
        message: t('rewards.batch.upload.error'),
        description: t('rewards.batch.upload.error.Description'),
        className: 'error',
        isClosable: true,
      });
    }
  };
  const onRemoveFile = () => {
    setUploadData(null);
    openNotification({
      message: t('rewards.batch.removed'),
      description: t('rewards.batch.removed.description'),
      className: 'success',
      isClosable: true,
    });
  };

  const onFileAdded = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      Papa.parse(e.target.result, {
        header: true,
        complete: (results) => {
          if (results.data) setUploadData(results.data);
          setDisableButton(false);
        },
      });
    };
    reader.readAsText(file);
  };

  const handleOfferCodeChange = useCallback(
    (offerCode) => {
      setBatchOfferCodes(() => {
        const values = _.map(productsFormRef.current.values, (item) => {
          const newItem = _.cloneDeep(item);
          newItem.offerCodeIds = [offerCode];
          return newItem;
        });

        productsFormRef.current.resetForm({ values });

        return [offerCode];
      });
    },
    [setBatchOfferCodes, productsFormRef]
  );

  const handleItemOfferCodeChange = () => {
    const offerCodesList = productsFormRef?.current?.values?.map((item) => item.offerCodeIds);
    const commonOfferCodes =
      offerCodesList.length === 0
        ? []
        : offerCodesList?.reduce((previousValue, currentValue) =>
            previousValue.filter((offerCode) => currentValue.includes(offerCode))
          );
    setBatchOfferCodes(commonOfferCodes);
  };

  const onUpload = () => {
    if (uploadedData) {
      const itemList = productsFormRef?.current?.values?.map((item) => ({ ...item, batchId }));
      if (_.isEmpty(itemList)) {
        openNotification({
          message: t('rewards.batch.error.noItems.message'),
          description: t('rewards.batch.error.noItems.description'),
          className: 'error',
          isClosable: true,
        });
      } else {
        onReadCSV(uploadedData);
        openNotification({
          message: t('rewards.batch.uploaded'),
          description: t('rewards.batch.uploaded.description'),
          className: 'success',
          isClosable: true,
        });
      }
    }
  };

  const isDataLoading = batchFetching || batchUpdating || statusUpdating || batchCreating || itemsLoading;
  const hideBatchInfo =
    batchId !== NEW_BATCH &&
    selectedBatch?.batchId &&
    _.toNumber(selectedBatch?.batchId) !== _.toNumber(batchId) &&
    batchFetching;

  return (
    <EditBatchPageComponent
      selectedBatch={selectedBatch}
      isDataLoading={isDataLoading}
      handleChange={handleChange}
      keyword={keyword || ''}
      showBatchResults={showBatchResults}
      visibleUpload={createUploadModalVisible}
      handleDownloadBatch={handleDownloadBatch}
      onToggleUploadModalVisible={onToggleUploadModalVisible}
      date={getDate()}
      navigateBack={navigateBack}
      productsFormRef={productsFormRef}
      onSave={onBatchSave}
      saveBatch={saveBatch}
      saveAndSubmitBatch={saveAndSubmitBatch}
      handleChangeSize={handleChangeSize}
      onCommentChange={onCommentChange}
      commentValue={batchSubmitComment}
      userRole={userRole}
      itemFilters={itemFilters}
      onChangeFile={onChangeFile}
      onRemoveFile={onRemoveFile}
      onFileAdded={onFileAdded}
      onUpload={onUpload}
      disableButton={disableButton}
      activeOfferCodes={activeOfferCodes}
      handleOfferCodeChange={handleOfferCodeChange}
      batchOfferCodes={batchOfferCodes}
      user={user}
      handleItemOfferCodeChange={handleItemOfferCodeChange}
      rejectedItemsCount={rejectedItemsCount}
      batchComments={batchComments}
      onBatchCommentsChange={onBatchCommentsChange}
      onItemCommentsChange={onItemCommentsChange}
      handleItemRejection={handleItemRejection}
      approveBatch={approveBatch}
      rejectBatch={rejectBatch}
      hideBatchInfo={hideBatchInfo}
      isDeleteBatchModalVisible={isDeleteBatchModalVisible}
      onToggleDeleteBatchModalVisible={onToggleDeleteBatchModalVisible}
      onDelete={onDelete}
    />
  );
}

function mapStateToProps(state) {
  return {
    selectedBatch: state.batch.data,
    batchFetching: state.batch.fetching,
    batchUpdating: state.updateBatch.fetching,
    itemsLoading: state.loadRewardItems.fetching,
    batchCreating: state.createBatch.fetching,
    statusUpdating: state.saveAndUpdateBatchStatus.fetching,
    userRole: state.user.data.userRole,
    user: state.user.data,
    vendorId: !_.isEmpty(state.user.data.vendorList) ? state.user.data.vendorList[0].vendorId : null,
    offerCodeList: state.offerCodes.data.items,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(batchesActions, dispatch) };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditBatchPage);
