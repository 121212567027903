import { action } from 'reduxHelpers';
import {
  BATCH,
  BATCHES,
  USER,
  ITEMS,
  UPDATE_FILTERS,
  UPDATE_BATCH,
  SAVE_UPDATE_BATCH_STATUS,
  SHOW_NOTIFICATION,
  OFFER_CODE,
  OFFER_CODES,
  CREATE_BATCH,
  UPDATE_OFFER_CODE_FILTERS,
  UPDATE_OFFER_CODE,
  CREATE_OFFER_CODE,
  FETCH_ALL_OFFER_CODES,
  DELETE_BATCH,
} from './actionTypes';

const loadUserDetails = (userId) => action(USER.REQUEST, { userId });
const loadBatchDetails = (selectedBatchId) => action(BATCH.REQUEST, { selectedBatchId });
const loadBatchesDetails = () => action(BATCHES.REQUEST);
const loadRewardItems = (vendorId, rewardType, batchFY, batchQuarter) =>
  action(ITEMS.REQUEST, { vendorId, rewardType, batchFY, batchQuarter });
const createBatch = (batchData, history) => action(CREATE_BATCH.REQUEST, { batchData, history });
const updateBatch = (batchId, data, history, redirectToBatchView = false, shouldAvoidBatchLoading = false) =>
  action(UPDATE_BATCH.REQUEST, {
    batchId,
    data,
    redirectToBatchView,
    history,
    shouldAvoidBatchLoading,
  });
const updateFilters = (data) => action(UPDATE_FILTERS.REQUEST, { data });
const saveAndUpdateBatchStatus = (data) => action(SAVE_UPDATE_BATCH_STATUS.REQUEST, { data });
const showNotification = (message, description, notificationType) =>
  action(SHOW_NOTIFICATION, { message, description, className: notificationType });
const loadOfferCodesDetails = () => action(OFFER_CODES.REQUEST);
const loadOfferCodeDetails = (selectedOfferCodeId) => action(OFFER_CODE.REQUEST, { selectedOfferCodeId });
const createOfferCode = (data) => action(CREATE_OFFER_CODE.REQUEST, { data });
const updateOfferCodeFilters = (data) => action(UPDATE_OFFER_CODE_FILTERS.REQUEST, { data });
const updateOfferCode = (data, history) =>
  action(UPDATE_OFFER_CODE.REQUEST, {
    data,
    history,
  });
const loadAllOfferCodes = () => action(FETCH_ALL_OFFER_CODES.REQUEST);
const deleteBatch = (selectedBatchId, redirectToBatchView = false, history) =>
  action(DELETE_BATCH.REQUEST, { selectedBatchId, redirectToBatchView, history });

export {
  loadUserDetails,
  loadBatchDetails,
  loadBatchesDetails,
  loadRewardItems,
  updateBatch,
  updateFilters,
  saveAndUpdateBatchStatus,
  loadOfferCodesDetails,
  loadOfferCodeDetails,
  showNotification,
  createBatch,
  updateOfferCodeFilters,
  updateOfferCode,
  createOfferCode,
  loadAllOfferCodes,
  deleteBatch,
};
