import React, { useCallback, useContext, useState } from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';

import OfferCodesListPageContext from 'context/OfferCodesListContext';
import { BATCHES_PAGE_SIZE, NOT_APPLICABLE } from 'util/constants';

const getColumns = (t) => [
  {
    title: t('rewards.offerCode'),
    dataIndex: 'offerCodeId',
  },
  {
    title: t('rewards.offerCode.startDate'),
    dataIndex: 'startDate',
    defaultSortOrder: 'descend',
  },
  {
    title: t('rewards.offerCode.endDate'),
    dataIndex: 'endDate',
    defaultSortOrder: 'descend',
  },
  {
    title: t('rewards.offerCode.createdBy'),
    dataIndex: 'associateName',
  },
  {
    title: 'Used',
    dataIndex: 'usedCount',
    render: (text) => text || 0,
  },
  {
    title: t('rewards.offerCode.comments'),
    dataIndex: 'comment',
    render: (text) => text || NOT_APPLICABLE,
  },
  {
    title: t('rewards.rewardType'),
    dataIndex: 'rewardType',
    render: (text, record) => (
      <span
        id={`offer-code-list-${record?.offerCodeId}-reward-type`}
        className={`tag-rewards btn tag-${text?.toLowerCase()}`}
      >
        <span className={`tag-${text?.toLowerCase()}-icon`}></span>
        <span id={`offer-code-list-${record?.offerCodeId}-reward-type-text`}>
          {t(`rewards.type.${text?.toLowerCase()}`)}
        </span>
      </span>
    ),
  },
  {
    title: t('rewards.status'),
    dataIndex: 'status',
    render: (text, record) => (
      <span id={`offer-code-list-${record?.offerCodeId}-status`} className={`ant-tag state ${text?.toLowerCase()}`}>
        {text || NOT_APPLICABLE}
      </span>
    ),
  },
];

function OfferCodesList() {
  const { t } = useTranslation();
  const { offerCodes, offerCodesFetching, viewOfferCode, offerCodeFilters, onChangePage, totalOfferCodes } =
    useContext(OfferCodesListPageContext);
  const [gridScrollHeight, setGridScrollHeight] = useState('70vh');

  const onResize = useCallback(
    (_0, height) => {
      setGridScrollHeight(height - 100);
    },
    [setGridScrollHeight]
  );

  return (
    <div className="grid-panel">
      <ReactResizeDetector handleHeight onResize={onResize}>
        <Table
          className="costing-batches-table"
          columns={getColumns(t)}
          dataSource={offerCodes}
          loading={offerCodesFetching}
          pagination={{
            defaultPageSize: BATCHES_PAGE_SIZE,
            current: offerCodeFilters?.page,
            total: totalOfferCodes,
            onChange: onChangePage,
          }}
          rowClassName="ant-clickable-row"
          onRow={(record) => ({
            onClick: () => viewOfferCode(record.offerCodeId),
          })}
          scroll={{ x: '100%', y: gridScrollHeight }}
        />
      </ReactResizeDetector>
    </div>
  );
}

export default OfferCodesList;
