import { Button } from 'antd';
import { UserRole } from 'enums';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { APP_ROUTES } from 'util/constants';
import { useTranslation } from 'react-i18next';

function RewardsTabs({ userRole, navigateToOfferCodesList, navigateToBatchesList }) {
  const { pathname } = useLocation();
  const isSyscoAssociate = userRole === UserRole.SYSCO_ASSOCIATE;
  const isBatchesPage = pathname.toLowerCase() === APP_ROUTES.BATCHES_LIST;
  const isOfferCodesPage = pathname.toLowerCase() === APP_ROUTES.OFFER_CODES_LIST.toLowerCase();
  const { t } = useTranslation();

  if (isSyscoAssociate)
    return (
      <div id="rewards-tab-menu" className="rewards-tab">
        <Button
          id="rewards-tab-batches-btn"
          type={isBatchesPage ? 'primary btn-tab-selected' : 'link'}
          onClick={navigateToBatchesList}
        >
          <span id="rewards-tab-batches" className={isBatchesPage ? 'reward-tab-icon-white' : 'reward-tab-icon'} />
          {t(`rewards.name`)}
        </Button>
        <Button
          id="rewards-tab-offer-codes-btn"
          type={isOfferCodesPage ? 'primary btn-tab-selected' : 'link'}
          onClick={navigateToOfferCodesList}
        >
          <span id="rewards-tab-offer-codes" className={isOfferCodesPage ? 'offer-tab-icon-white' : 'offer-tab-icon'} />
          {t(`rewards.offerCode`)}
        </Button>
      </div>
    );

  return null;
}

export default RewardsTabs;
