import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

function MassUpdateSelector({
  selectedItems,
  commonOfferCodeIds,
  updateOfferCode,
  batchesFetching,
  approveBatches,
  rejectBatches,
  isSameRewardTypeBatches,
  getMatchedOfferCodes,
}) {
  const { t } = useTranslation();

  return (
    <div id="mass-update-selector" className="grid-selection-panel animated fadeIn">
      <div id="mass-update-selector-selection-info" className="selection-info">
        <label id="mass-update-selector-check-label" className="ant-checkbox-wrapper ant-checkbox-wrapper-checked">
          <span id="mass-update-selector-checked-checkbox" className="ant-checkbox ant-checkbox-checked">
            <span id="mass-update-selector-checked-checkbox-inner" className="ant-checkbox-inner"></span>
          </span>
        </label>
        <div className="count" id="grid-selectedRowCount">
          {t('rewards.batches.selectedItems', { selectedCount: selectedItems?.length })}
        </div>
        <span id="mass-update-selected-label">{t('rewards.selected')}</span>
      </div>
      <div className="spacer"></div>
      <div id="mass-update-selector-actions" className="selection-actions code-multi-select-wrap">
        {isSameRewardTypeBatches() && (
          <>
            <span id="mass-update-selector-code-label" className="lable">
              {t(`rewards.batchItem.offerCode`)}
            </span>
            <Select
              id="mass-update-selector-offer-code-selection"
              disabled={batchesFetching}
              defaultValue={commonOfferCodeIds}
              dropdownMatchSelectWidth={false}
              className="code-multi-select"
              onChange={updateOfferCode}
              style={{ width: '200px', maxHeight: '64px', overflow: 'auto' }}
            >
              {getMatchedOfferCodes(selectedItems[0]?.rewardType)?.map(({ offerCodeId } = {}) => (
                <Option id={`mass-update-selector-offer-code-${offerCodeId}`} key={offerCodeId} value={offerCodeId}>
                  {offerCodeId}
                </Option>
              ))}
            </Select>
          </>
        )}
        <button
          id="batch-merchandiser-approve"
          type="button"
          className="ant-btn btn green-action-btn ant-btn-primary"
          onClick={approveBatches}
        >
          <i className="fi flaticon-tick-1"></i>
          <span id="batch-merchandiser-approve-label">{t(`rewards.approve`)}</span>
        </button>
        <button
          id="batch-merchandiser-reject"
          type="button"
          className="ant-btn btn red-action-btn ant-btn-primary"
          onClick={rejectBatches}
        >
          <i className="fi flaticon-round-delete-button"></i>
          <span id="batch-merchandiser-reject-label">{t(`rewards.reject`)}</span>
        </button>
      </div>
    </div>
  );
}

export default MassUpdateSelector;
