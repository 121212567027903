import React from 'react';

import Header from 'pages/EditBatchesPage/components/BatchHeader';
import UploadModel from 'components/UploadModel';
import AppLoader from 'components/AppLoader';
import ProductList from './ProductList';

export default function EditBatchPageComponent({
  selectedBatch,
  isDataLoading,
  userRole,
  onToggleUploadModalVisible,
  handleDownloadBatch,
  visibleUpload,
  handleChange,
  showBatchResults,
  keyword,
  handleChangeSize,
  date,
  navigateBack,
  productsFormRef,
  onSave,
  saveBatch,
  saveAndSubmitBatch,
  onCommentChange,
  commentValue,
  itemFilters,
  onRemoveFile,
  onFileAdded,
  onUpload,
  onChangeFile,
  disableButton,
  activeOfferCodes,
  handleOfferCodeChange,
  batchOfferCodes,
  user,
  handleItemOfferCodeChange,
  rejectedItemsCount,
  batchComments,
  onBatchCommentsChange,
  onItemCommentsChange,
  handleItemRejection,
  approveBatch,
  rejectBatch,
  hideBatchInfo,
  isDeleteBatchModalVisible,
  onToggleDeleteBatchModalVisible,
  onDelete,
}) {
  return (
    <>
      {isDataLoading && <AppLoader show />}
      <Header
        userRole={userRole}
        showUploadModal={onToggleUploadModalVisible}
        handleDownloadBatch={handleDownloadBatch}
        selectedBatch={selectedBatch}
        handleChange={handleChange}
        showBatchResults={showBatchResults}
        keyword={keyword}
        handleChangeSize={handleChangeSize}
        date={date}
        navigateBack={navigateBack}
        saveBatch={saveBatch}
        saveAndSubmitBatch={saveAndSubmitBatch}
        onCommentChange={onCommentChange}
        commentValue={commentValue}
        itemFilters={itemFilters}
        activeOfferCodes={activeOfferCodes}
        handleOfferCodeChange={handleOfferCodeChange}
        batchOfferCodes={batchOfferCodes}
        user={user}
        rejectedItemsCount={rejectedItemsCount}
        batchComments={batchComments}
        onBatchCommentsChange={onBatchCommentsChange}
        approveBatch={approveBatch}
        rejectBatch={rejectBatch}
        hideBatchInfo={hideBatchInfo}
        isDeleteBatchModalVisible={isDeleteBatchModalVisible}
        onToggleDeleteBatchModalVisible={onToggleDeleteBatchModalVisible}
        onDelete={onDelete}
      />

      <ProductList
        items={selectedBatch?.opcoItems}
        onSave={onSave}
        productsFormRef={productsFormRef}
        batchStatus={selectedBatch?.batchStatus}
        searchKeyword={keyword}
        itemFilters={itemFilters}
        activeOfferCodes={activeOfferCodes}
        userRole={user?.userRole}
        handleItemOfferCodeChange={handleItemOfferCodeChange}
        onItemCommentsChange={onItemCommentsChange}
        handleItemRejection={handleItemRejection}
        hideBatchInfo={hideBatchInfo}
      />
      <UploadModel
        visible={visibleUpload}
        closeCreateModal={onToggleUploadModalVisible}
        onRemoveFile={onRemoveFile}
        onFileAdded={onFileAdded}
        onUpload={onUpload}
        onChangeFile={onChangeFile}
        disableButton={disableButton}
        batchId={selectedBatch?.batchId}
      />
    </>
  );
}
