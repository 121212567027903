import React from 'react';

const OfferCodeViewPageContext = React.createContext({
  offerCode: null,
  isDataLoading: true,
  updateOfferCode: null,
  navigateToOfferCodeList: null,
});

export const OfferCodeViewPageContextProvider = OfferCodeViewPageContext.Provider;

export default OfferCodeViewPageContext;
