import React, { useContext } from 'react';
import { Button, Col, Row, DatePicker as AntdDatePicker } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import { Formik } from 'formik';
import { DatePicker, Form, Input, Select } from 'formik-antd';
import * as _ from 'lodash';
import * as yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { OfferCodeStatus, RewardType } from 'enums';
import OfferCodeFormContext from 'context/OfferCodeFormContext';
import { ISO_DATE_FORMAT, OFFER_CODE_MAX_LENGTH } from 'util/constants';

const { Option } = Select;
// eslint-disable-next-line no-unused-vars
const { RangePicker } = AntdDatePicker; // This line is required for formik-antd DatePicker to work properly

const validOfferCodeStatuses = _.values(OfferCodeStatus);
const validRewardTypes = _.values(RewardType);

const renderFormError = (error) => <p style={{ color: 'red' }}>{error}</p>;

function OfferCodeForm() {
  const { offerCodeFormRef, offerCode, onSave, isEditing } = useContext(OfferCodeFormContext);
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    rewardType: yup.string().oneOf(validRewardTypes).required(t('rewards.offerCodeForm.type.required')),
    offerCodeId: yup
      .string()
      .max(OFFER_CODE_MAX_LENGTH, t('rewards.offerCodeForm.id.tooLong', { offerCodeLength: OFFER_CODE_MAX_LENGTH }))
      .required(t('rewards.offerCodeForm.id.required')),
    startDate: isEditing
      ? yup
          .date(t('rewards.offerCodeForm.startDate.invalid'))
          .typeError(t('rewards.offerCodeForm.startDate.invalid'))
          .nullable()
          .optional()
      : yup
          .date(t('rewards.offerCodeForm.startDate.invalid'))
          .typeError(t('rewards.offerCodeForm.startDate.invalid'))
          .nullable()
          .min(moment(), t('rewards.offerCodeForm.startDate.invalid'))
          .required(t('rewards.offerCodeForm.startDate.required')),
    endDate: yup
      .date(t('rewards.offerCodeForm.endDate.invalid'))
      .typeError(t('rewards.offerCodeForm.endDate.invalid'))
      .nullable()
      .min(moment(), t('rewards.offerCodeForm.endDate.invalid'))
      .transform((value) => (moment(value).isValid() ? value : null))
      .required(t('rewards.offerCodeForm.endDate.required'))
      .when(
        'startDate',
        (startDate, schema) => (startDate ? schema.min(startDate, t('rewards.offerCodeForm.endDate.invalid')) : schema),
        t('rewards.offerCodeForm.endDate.invalid')
      ),
    comments: yup.string(),
    status: isEditing ? yup.string().oneOf(validOfferCodeStatuses) : yup.string().optional(),
  });

  return (
    <Formik
      innerRef={offerCodeFormRef}
      id="productListForm"
      onSubmit={(values, { setSubmitting, resetForm }) => {
        onSave(values);
        setSubmitting(false);
        resetForm({ values });
      }}
      initialValues={offerCode}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      {({ values: { rewardType, startDate, endDate, offerCodeId }, setFieldValue, errors, touched }) => (
        <Form>
          <Row>
            <Col span={8}>
              <label id="offer-code-form-reward-type-label" className="new-offercode-lable">
                {t(`rewards.rewardType`)}
              </label>
            </Col>
            <Col span={16}>
              <ButtonGroup id="offer-code-form-reward-type-btn-group" className="btn-group-wrap">
                {validRewardTypes.map((type) => {
                  const lowerCaseType = _.lowerCase(type);
                  return (
                    <Button
                      key={lowerCaseType}
                      id={`offer-code-form-reward-type-btn-${lowerCaseType}`}
                      className={rewardType === type ? 'tag-type-selected' : ''}
                      onClick={() => (isEditing ? '' : setFieldValue('rewardType', type))}
                    >
                      <span
                        id={`offer-code-form-reward-type-icon-${lowerCaseType}`}
                        className={`tag-${lowerCaseType}-group-icon`}
                      />
                      <span id={`offer-code-form-reward-type-title-${lowerCaseType}`} className="btn-title">
                        {t(`rewards.type.${lowerCaseType}`)}
                      </span>
                    </Button>
                  );
                })}
              </ButtonGroup>
            </Col>
          </Row>
          <Row className="form-elements">
            <Col span={8}>
              <label id="offer-code-form-offer-code-title-lable" className="new-offercode-lable">
                {t(`rewards.offerCode.codeName`)}
              </label>
            </Col>
            <Col span={16}>
              <Input
                id="offer-code-form-offer-code-id-input"
                name="offerCodeId"
                size="default"
                placeholder={t('rewards.offerCode.codeName')}
                disabled={isEditing}
                maxLength={OFFER_CODE_MAX_LENGTH}
              />
              {errors.offerCodeId && touched.offerCodeId && renderFormError(errors.offerCodeId)}
            </Col>
          </Row>
          <Row className="form-elements">
            <Col span={8}>
              <label id="offer-code-form-offer-code-start-date-label" className="new-offercode-lable">
                {t(`rewards.offerCode.startDate`)}
              </label>
            </Col>
            <Col span={16}>
              <DatePicker
                id="offer-code-form-offer-code-start-date"
                value={startDate ? moment(startDate, ISO_DATE_FORMAT) : null}
                className="offercode-date-picker"
                disabledDate={(date) => date < moment()}
                disabled={isEditing}
                onChange={(date) => setFieldValue('startDate', date?.format(ISO_DATE_FORMAT) || null)}
              />
              {errors.startDate && touched.startDate && renderFormError(errors.startDate)}
            </Col>
          </Row>
          <Row className="form-elements">
            <Col span={8}>
              <label id="offer-code-form-offer-code-end-date-label" className="new-offercode-lable">
                {t(`rewards.offerCode.endDate`)}
              </label>
            </Col>
            <Col span={16}>
              <DatePicker
                id="offer-code-form-offer-code-end-date"
                value={endDate ? moment(endDate, ISO_DATE_FORMAT) : null}
                className="offercode-date-picker"
                disabledDate={(date) => (startDate && date < moment(startDate, ISO_DATE_FORMAT)) || date < moment()}
                onChange={(date) => setFieldValue('endDate', date?.format(ISO_DATE_FORMAT) || null)}
                disabled={isEditing && _.isEmpty(offerCodeId)}
              />
              {errors.endDate && touched.endDate && renderFormError(errors.endDate)}
            </Col>
          </Row>
          {isEditing && (
            <Row className="form-elements">
              <Col span={8}>
                <label id="offer-code-form-offer-code-status-label" className="new-offercode-lable">
                  {t(`rewards.offerCode.status`)}
                </label>
              </Col>
              <Col span={16}>
                <Select
                  id="offer-code-form-offer-code-status-dropdown"
                  name="status"
                  className="offer-code-status-dropdown"
                  defaultValue="lucy"
                  disabled={isEditing}
                >
                  {validOfferCodeStatuses.map((val) => (
                    <Option value={val}>{val}</Option>
                  ))}
                </Select>
              </Col>
            </Row>
          )}
          <Row className="form-elements">
            <Col span={8}>
              <label id="offer-code-form-offer-code-comments-label" className="new-offercode-lable">
                {t(`rewards.offerCode.comments`)}
              </label>
            </Col>
            <Col span={16}>
              <Input
                id="offer-code-form-offer-code-comments-input"
                name="comment"
                size="default"
                placeholder={t('rewards.comments')}
                disabled={isEditing && _.isEmpty(offerCodeId)}
              />
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}

export default OfferCodeForm;
